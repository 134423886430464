import React from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingMaintenanceQuestion.module.css'

const BuildingMaintenance1Question = ({ clearError }) => {
  const { formData, updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;

  const structureMaintenance = building?.structureMaintenance || formData?.structureMaintenance;
  const liningMaintenance = building?.liningMaintenance || formData?.liningMaintenance;
  const waterPipeMaintenance = building?.waterPipeMaintenance || formData?.waterPipeMaintenance;
  const sewageMaintenance = building?.sewageMaintenance || formData?.sewageMaintenance;

  const updateFunction = updateBuilding || updateFormData;

  const handleLabelClick = (field, value) => {
    const currentValue = field === "structureMaintenance" ? structureMaintenance :
                         field === "liningMaintenance" ? liningMaintenance :
                         field === "waterPipeMaintenance" ? waterPipeMaintenance :
                         field === "sewageMaintenance" ? sewageMaintenance : '';

    const newValue = currentValue === value ? '' : value;
    updateFunction(field, newValue);
    clearError();
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 13</h4>
      <h2 className={styles.title}>Maintenance 1/3 (optional)</h2>

      <label className={styles.label}>Structure</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${structureMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("structureMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <label className={styles.label}>Lining</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${liningMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("liningMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <label className={styles.label}>Water pipe</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${waterPipeMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("waterPipeMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <label className={styles.label}>Sewage</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${sewageMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("sewageMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <div className={styles.separator} />
    </div>
  );
};

export default BuildingMaintenance1Question;
