import React from "react";
import { useFormData } from "../components/FormDataContext";
import { useEditContext } from "../components/EditContext";
import styles from "./css/BuildingMaintenance2Question.module.css";

const BuildingMaintenance3Question = ({ clearError }) => {
  const { formData, updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;
  const updateFunction = updateBuilding || updateFormData;

  const fireProtectionMaintenance = building?.fireProtectionMaintenance || formData?.fireProtectionMaintenance;
  const securitySystemsMaintenance = building?.securitySystemsMaintenance || formData?.securitySystemsMaintenance;

  const handleLabelClick = (field, value) => {
    const currentValue = field === "fireProtectionMaintenance" ? fireProtectionMaintenance :
    field === "securitySystemsMaintenance" ? securitySystemsMaintenance : '';

    const newValue = currentValue === value ? '' : value;
    updateFunction(field, newValue);
    clearError();
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 15</h4>
      <h2 className={styles.title}>Maintenance 3/3 (optional)</h2>

      <label className={styles.label}>Fire protection systems</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${fireProtectionMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("fireProtectionMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <label className={styles.label}>Security systems </label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${securitySystemsMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("securitySystemsMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <div className={styles.separator} />
    </div>
  );
};

export default BuildingMaintenance3Question;
