import React from "react";
import { useFormData } from "../components/FormDataContext";
import { useEditContext } from "../components/EditContext";
import styles from './css/CityAddressQuestion.module.css';

const CityAddresQuestion = ({clearError}) => {
    const { formData, updateFormData } = useFormData();
    const editContextData = useEditContext();
    const building = editContextData?.building;
    const updateBuilding = editContextData?.updateBuilding;
    const updateFunction = updateBuilding || updateFormData;


    const handleChange = (field) => (e) => {
        const value = e.target.value;
        updateFunction(field, value);
        clearError();
    };

    const city = building?.city || formData.city;
    const address = building?.address || formData.address;
    const constructionYear = building?.constructionYear || formData.constructionYear;

    const handleYearInput = (e) => { 
        const value = e.target.value;
        if (/^\d*$/.test(value)) { 
            updateFunction('constructionYear', value);
        } 
    };

    return (
    <div className={styles.container}>
        <h4 className={styles.questionNumber}>Question 2</h4>
        <h2 className={styles.title}>Address</h2>
        <input 
            type="text" 
            value={address} 
            onChange={handleChange('address')}
            placeholder="Enter address"
            className={styles.input} />

        <h2 className={styles.city}>City</h2>
        <div className={styles.options}>
        {[
            { value: 'Belgrade', label: 'Belgrade' },
            { value: 'Novi Sad', label: 'Novi Sad' },
            { value: 'Kragujevac', label: 'Kragujevac' },
            ].map((option) => (
            <label key={option.value} className={styles.label}>
                <input
                type="radio"
                name="city"
                value={option.value}
                checked={city === option.value}
                onChange={handleChange('city')}
                />
                {option.label}
            </label>
        ))}
        </div>
        <h2 className={styles.title}> Construction year</h2>
        <input 
            type="text"
            inputMode="numeric" 
            value={constructionYear} 
            pattern="[0-9]+"
            onChange={handleYearInput}
            placeholder='Enter year of construction'            
            className={styles.input} />
    </div>
    );
};

export default CityAddresQuestion;
