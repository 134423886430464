import React from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/ConstructiveSystemQuestion.module.css'

const ConstructiveSystemQuestion = ({clearError}) => {
    const { formData, updateFormData } = useFormData();
    const editContextData = useEditContext();
    const building = editContextData?.building;
    const updateBuilding = editContextData?.updateBuilding;
  
    const constructiveSystem = building?.constructiveSystem || formData?.constructiveSystem;
    const updateFunction = updateBuilding || updateFormData;

    const handleInputChange = (field, value) => {
        updateFunction(field, value);
        clearError();
    };

    return (
        <div className={styles.container}>
            <h4 className={styles.questionNumber}>Question 8</h4>
            <h2 className={styles.title}>Constructive system</h2>
            <div className={styles.options} >
                {[
                { value: 'Skeletal RC', label: 'Skeletal RC' },
                { value: 'Reinforced walls', label: 'Reinforced walls' },
                { value: 'Massive masonry', label: 'Massive masonry' },
                { value: 'Prefabricated panel', label: 'Prefabricated panel' },
                { value: 'Prefabricated skeletal', label: 'Prefabricated skeletal' },
                { value: 'Prefabricated skeletal prestressed IMS', label: 'Prefabricated skeletal prestressed IMS' },
                { value: 'Massive masonry reinforced with RC elements', label: 'Massive masonry reinforced with RC elements' },
                { value: 'Prefabricated skeletal prestressed IMS with stiffening', label: 'Prefabricated skeletal prestressed IMS with stiffening' },
                ].map((option) => (
                <label key={option.value} className={styles.label}>
                    <input
                    type="radio"
                    name="constructiveSystem"
                    value={option.value}
                    checked={constructiveSystem === option.value}
                    onChange={(e) => handleInputChange('constructiveSystem', e.target.value)}
                    />
                    <span>{option.label}</span>
                </label>
                ))}
            </div>
        </div>
    );
};

export default ConstructiveSystemQuestion;
