import { useState } from "react";
import supabase from "./config/supabaseClient";
import styles from "./styles/Login.module.css"

const Login = ({ setUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [, setIsAdmin] = useState(false)

  const handleLogin = async (e) => {
    e.preventDefault();

    const { data: userData, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    const adminID = "c276a39b-4d9e-4fcb-a097-e5d81180a9fc"
    
    const {data: { user }} = await supabase.auth.getUser();

    if (user) {
      if (user.id !== adminID) {
        setIsAdmin(false);
        console.log("You are not admin")
      }
      if (user.id === adminID) {
        setIsAdmin(true)
        console.log("You are admin");
      }
    }

    const emailConfirm = sessionStorage.getItem("email");

    if (userData) {
      setUser(userData);
      setLoginError("");
      localStorage.setItem("user", JSON.stringify(userData));
    }

    if (email !== emailConfirm) {
      setLoginError("Invalid credentials");
    } else if (password.length < 6) {
      setLoginError("Invalid credentials");
    }

    if (error) {
      setUser(null);
      localStorage.removeItem("user");
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.loginContainer}>
        <div className={styles.formContainer}>
          <a href="https://new.gradjevinans.net/">
            <img src="/ftn-departman-logo.png" alt="FTN logo" className={styles.departmentImage} />
          </a>
          <form onSubmit={handleLogin} className={styles.form}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>Build Collector</h2>
            </div>

            <div className={styles.inputContainer}>
              <label htmlFor="email" className={styles.inputLabel}>Email</label>
              <input
                name="email"
                placeholder="Enter email"
                type="text"
                className={styles.inputBox}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="password" className={styles.inputLabel}>Password</label>
              <input
                name="password"
                placeholder="Enter password"
                type="password"
                className={styles.inputBox}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label className={styles.errorLabel}>{loginError}</label>
            </div>
            <div className={styles.inputContainer}>
              <button type="submit" className={styles.inputButton}>
                Log in
              </button>
            </div>
          </form>
        </div>
        <div className={styles.imageContainer}/>
      </div>
    </div>
  );
};

export default Login;
