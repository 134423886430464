import React from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingPurposeQuestion.module.css'

const BuildingPurposeQuestion = ({clearError}) => {
  const { formData, updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;

  const handleChange = (e) => {
    const value = e.target.value;
    const updateFunction = updateBuilding || updateFormData;
    updateFunction('buildingPurpose', value);
    clearError();
  };

  const selectedValue = building?.buildingPurpose || formData?.buildingPurpose;

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 1</h4>
      <h2 className={styles.title}>Building purpose</h2>
      <div className={styles.options} >
        {[
          { value: 'Residential', label: 'Residential' },
          { value: 'Hotels', label: 'Hotels' },
          { value: 'Hospitals', label: 'Hospitals' },
          { value: 'Business', label: 'Business' },
          { value: 'Theatres, movie theatres', label: 'Theatres, movie theatres' },
          { value: 'Shopping malls', label: 'Shopping malls' },
          { value: 'Business-residential', label: 'Business-residential' },
          { value: 'Garages', label: 'Garages' },
          { value: 'Bus stations, train stations, airports', label: 'Bus stations, train stations, airports' },
          { value: 'Kindergardens, schools and faculties', label: 'Kindergardens, schools and faculties' },
        ].map((option) => (
          <label key={option.value} className={styles.label}>
            <input
              type="radio"
              name="buildingPurpose"
              value={option.value}
              checked={selectedValue === option.value}
              onChange={handleChange}
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default BuildingPurposeQuestion;
