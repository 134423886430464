import styles from './styles/LandingPage.module.css';

const LandingPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <div className={styles.overlay}>
            <div className={styles.content}>
            <h1>Welcome to Build Collector</h1>
            <ul className={styles.features}>
                <li>📝 Collect detailed information about buildings effortlessly.</li>
                <li>📊 Visualize all the important data about collected buildings.</li>
                <li>🔍 Filter and search through collected data with ease.</li>
                <li>💾 Export data to Excel for further analysis and reporting.</li>
            </ul>
            </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
