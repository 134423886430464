import React from "react";
import styles from "./css/ImageUploadQuestion.module.css";

const ImageUploadQuestion = ({ imageFiles, setImageFiles, pdfFile, setPdfFile }) => {
  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files).slice(0, 5);
    setImageFiles(files);
  };

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    setPdfFile(file);
  };

  const handleRemoveImage = (index) => {
    setImageFiles((prevFiles) => prevFiles.filter((_, fileIndex) => fileIndex !== index));
  };
  
  console.log("Images:", imageFiles);
  console.log("PDF:", pdfFile);

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 17</h4>
      <h2 className={styles.title}>Upload Documents</h2>
      <div className={styles.uploadSection}>
        <label htmlFor="imageUpload" className={styles.fileLabel}>
          Upload Images (Max 5)
        </label>
        <input type="file" id="imageUpload" className={styles.fileInput} accept="image/*" multiple onChange={handleImageUpload} />
        <div className={styles.fileInfo}>
          {imageFiles && (
            <>
              {imageFiles.map((file, index) => (
                <div key={index} className={styles.fileWrapper}>
                  <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} className={styles.filePreview} />
                  <div onClick={() => handleRemoveImage(index)} className={styles.remove_icon}></div>
                  {/* <div>{file.name}</div> */}
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <div className={styles.uploadSection}>
        <label htmlFor="pdfUpload" className={styles.fileLabel}>
          Upload PDF
        </label>
        <input type="file" id="pdfUpload" className={styles.fileInput} accept="application/pdf" onChange={handlePdfUpload} />

        <div className={styles.fileInfo}>
          {pdfFile && (
            <div className={styles.fileWrapper}>
              <img src="/pdf-icon.jpeg" className={styles.filePreview} alt="pdf-icon" />
              <div onClick={() => setPdfFile(null)} className={styles.remove_icon_pdf}></div>
              {pdfFile.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageUploadQuestion;
