import React from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from "../components/EditContext";
import styles from './css/StructureOfBuildingQuestion.module.css'

const BuildingPurposeQuestion = ({clearError}) => {
    const { formData, updateFormData } = useFormData();
    const editContextData = useEditContext();
    const building = editContextData?.building;
    const updateBuilding = editContextData?.updateBuilding;
    const updateFunction = updateBuilding || updateFormData;

    const handleStructureChange = (e) => {
        const newStructure = e.target.value;
        updateFunction('structureOfBuilding', newStructure);
        updateFunction('floorsBelowGround', '');
        updateFunction('floorsAboveGround', '');
    
        if (!newStructure.includes('Basement')) {
          updateFunction('basementHeight', '');
          updateFunction('basementLevel', '');
        }
    
        if (!newStructure.includes('Attic')) {
          updateFunction('atticHeight', '');
        }
    
        clearError();
      };

    const handleFloorsBelowGroundInput = (e) => { 
        const value = e.target.value;
        if (/^\d*$/.test(value)) { 
            updateFunction('floorsBelowGround', value);
        } 
    };

    const handleFloorsAboveGroundInput = (e) => { 
        const value = e.target.value;
        if (/^\d*$/.test(value)) { 
            updateFunction('floorsAboveGround', value);
        } 
    };

    const structureOfBuilding = building?.structureOfBuilding || formData.structureOfBuilding;
    const floorsAboveGround = building?.floorsAboveGround || formData.floorsAboveGround;
    const floorsBelowGround = building?.floorsBelowGround || formData.floorsBelowGround;

    return (
    <div className={styles.container}>
    <div className={styles.topSection}> 
        <h4 className={styles.questionNumber}>Question 3</h4>
        <h2 className={styles.title}>Structure of building</h2>
        <div className={styles.options}>
        {[
        { value: 'GF + Floors', label: 'GF + Floors' },
        { value: 'GF + Floors + Attic', label: 'GF + Floors + Attic' },
        { value: 'Basement (Sub-ground floor) + GF + Floors', label: 'Basement (Sub-ground floor) + GF + Floors' },
        { value: 'Basement (Sub-ground floor) + GF + Floors + Attic', label: 'Basement (Sub-ground floor) + GF + Floors + Attic' },
        ].map((option) => (
            <label key={option.value} className={styles.label}>
            <input
                type="radio"
                name="structureOfBuilding"
                value={option.value}
                checked={structureOfBuilding === option.value}
                onChange={handleStructureChange}
            />
            <span>{option.label}</span>
            </label>
        ))}
        </div>
        <div className={styles.separator}></div>
    </div>
    <div className={styles.aboveGroundSection}>
        { structureOfBuilding && (
        <>
        <h2 className={styles.floorsBelowGround}>Floors above ground</h2>
        <input 
        type="text"
        inputMode="numeric" 
        value={floorsAboveGround} 
        pattern="[0-9]+"
        onChange={handleFloorsAboveGroundInput}
        placeholder='Enter the number of floors'  
        className={styles.input} />
        </>
        )}         
    </div>
    <div className={styles.belowGroundSection}>
    {structureOfBuilding &&
    structureOfBuilding.indexOf('Basement') !== -1 && (
    <>
        <h2 className={styles.floorsBelowGround}>Floors below ground</h2>
        <input 
            type="text"
            inputMode="numeric" 
            value={floorsBelowGround} 
            pattern="[0-9]+"
            onChange={handleFloorsBelowGroundInput}
            placeholder='Enter the number of floors'               
            className={styles.input} />
    </>
    )}         
    </div>
    </div>
    );
};

export default BuildingPurposeQuestion;
