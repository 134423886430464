import React from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingCoverQuestion.module.css'

const ConstructiveSystemQuestion = ({clearError}) => {
    const { formData, updateFormData } = useFormData();
    const editContextData = useEditContext();
    const building = editContextData?.building;
    const updateBuilding = editContextData?.updateBuilding;
  
    const cover = building?.cover || formData?.cover;
    const updateFunction = updateBuilding || updateFormData;

    const handleInputChange = (field, value) => {
        updateFunction(field, value);
        clearError();
    };

    return (
        <div className={styles.container}>
            <h4 className={styles.questionNumber}>Question 9</h4>
            <h2 className={styles.title}>Cover</h2>
            <div className={styles.options} >
                {[
                { value: 'Material for roof terraces (“Flat roof”)', label: 'Material for roof terraces (“Flat roof”)' },
                { value: 'Tiles and a like', label: 'Tiles and a like' },
                { value: 'Fiber-cement panels', label: 'Fiber-cement panels' },
                { value: 'Canadian shingles', label: 'Canadian shingles' },
                { value: 'Sheet metal', label: 'Sheet metal' },
                ].map((option) => (
                <label key={option.value} className={styles.label}>
                    <input
                    type="radio"
                    name="cover"
                    value={option.value}
                    checked={cover === option.value}
                    onChange={(e) => handleInputChange('cover', e.target.value)}
                    />
                    <span>{option.label}</span>
                </label>
                ))}
            </div>
        </div>
    );
};

export default ConstructiveSystemQuestion;
