import React from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingRoofDetailsQuestion.module.css'

const BuildingRoofDetailsQuestion = ({clearError}) => {
    const { formData, updateFormData } = useFormData();
    const editContextData = useEditContext();
    const building = editContextData?.building;
    const updateBuilding = editContextData?.updateBuilding;
  
    const roofType = building?.roofType || formData?.roofType;
    const roofMaterial = building?.roofMaterial || formData?.roofMaterial; 
    const updateFunction = updateBuilding || updateFormData;

    const handleInputChange = (field, value) => {
        updateFunction(field, value);
        clearError();
    };

    return (
        <div className={styles.container}>
            <h4 className={styles.questionNumber}>Question 7</h4>
            <h2 className={styles.title}>Roof type</h2>
            <div className={styles.options} >
                {[
                { value: 'Roof terrace (“Flat roof”)', label: 'Roof terrace (“Flat roof”)' },
                { value: 'Roof (regular/mansardi)', label: 'Roof (regular/mansardi)' },
                { value: 'Curved (arched)', label: 'Curved (arched)' },
                ].map((option) => (
                <label key={option.value} className={styles.label}>
                    <input
                    type="radio"
                    name="roofType"
                    value={option.value}
                    checked={roofType === option.value}
                    onChange={(e) => handleInputChange('roofType', e.target.value)}
                    />
                    <span>{option.label}</span>
                </label>
                ))}
            </div>

            <h2 className={styles.title}>Roof material construction</h2>
            <div className={styles.options} >
                {[
                { value: 'Classic wooden construction', label: 'Classic wooden construction' },
                { value: 'LRF (Light Roof Fasteners)', label: 'LRF (Light Roof Fasteners)' },
                { value: 'Steel construction', label: 'Steel construction' },
                { value: 'Concrete construction', label: 'Concrete construction' },
                { value: 'Other', label: 'Other' },
                ].map((option) => (
                <label key={option.value} className={styles.label}>
                    <input
                    type="radio"
                    name="roofMaterial"
                    value={option.value}
                    checked={roofMaterial === option.value}
                    onChange={(e) => handleInputChange('roofMaterial', e.target.value)}
                    />
                    <span>{option.label}</span>
                </label>
                ))}
            </div>
        </div>
    );
};

export default BuildingRoofDetailsQuestion;
