import React, { useState, useEffect } from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingInterventionsQuestion.module.css';

const BuildingInterventionsQuestion = ({ clearError }) => {
  const { formData, updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;
  const updateFunction = updateBuilding || updateFormData;

  const initialCheckedStates = {
    extension: Boolean(building?.extensionDetails || formData?.extensionDetails),
    upgrade: Boolean(building?.upgradeDetails || formData?.upgradeDetails),
    strengthening: Boolean(building?.strengtheningDetails || formData?.strengtheningDetails),
    weakening: Boolean(building?.weakeningDetails || formData?.weakeningDetails),
  };

  const [checkedStates, setCheckedStates] = useState(initialCheckedStates);

  const initialInputValues = {
    extensionDetails: building?.extensionDetails || formData?.extensionDetails || '',
    upgradeDetails: building?.upgradeDetails || formData?.upgradeDetails || '',
    strengtheningDetails: building?.strengtheningDetails || formData?.strengtheningDetails || '',
    weakeningDetails: building?.weakeningDetails || formData?.weakeningDetails || '',
  };

  const [inputValues, setInputValues] = useState(initialInputValues);

  useEffect(() => {
    updateFunction('extensionDetails', inputValues.extensionDetails);
    updateFunction('upgradeDetails', inputValues.upgradeDetails);
    updateFunction('strengtheningDetails', inputValues.strengtheningDetails);
    updateFunction('weakeningDetails', inputValues.weakeningDetails);
  }, [checkedStates, inputValues, updateFunction]);

  const handleCheckboxChange = (field) => {
    setCheckedStates((prev) => {
      const newState = !prev[field];
      if (!newState) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [`${field}Details`]: '',
        }));
      }
      return {
        ...prev,
        [field]: newState,
      };
    });
    clearError();
  };

  const handleInputChange = (field, value) => {
    setInputValues((prev) => ({
      ...prev,
      [field]: value,
    }));
    clearError();
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 11</h4>
      <h2 className={styles.title}>Noted interventions on building (optional)</h2>

      <div className={styles.intervention}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={checkedStates.extension}
            onChange={() => handleCheckboxChange('extension')}
            className={styles.checkbox}
          />
          Extension
        </label>
        {checkedStates.extension && (
          <input
            type="text"
            value={inputValues.extensionDetails}
            onChange={(e) => handleInputChange('extensionDetails', e.target.value)}
            className={styles.input}
            placeholder="Enter extension details"
          />
        )}
      </div>

      <div className={styles.intervention}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={checkedStates.upgrade}
            onChange={() => handleCheckboxChange('upgrade')}
            className={styles.checkbox}
          />
          Upgrade
        </label>
        {checkedStates.upgrade && (
          <input
            type="text"
            value={inputValues.upgradeDetails}
            onChange={(e) => handleInputChange('upgradeDetails', e.target.value)}
            className={styles.input}
            placeholder="Enter upgrade details"
          />
        )}
      </div>

      <div className={styles.intervention}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={checkedStates.strengthening}
            onChange={() => handleCheckboxChange('strengthening')}
            className={styles.checkbox}
          />
          Strengthening of the Structure
        </label>
        {checkedStates.strengthening && (
          <input
            type="text"
            value={inputValues.strengtheningDetails}
            onChange={(e) => handleInputChange('strengtheningDetails', e.target.value)}
            className={styles.input}
            placeholder="Enter strengthening details"
          />
        )}
      </div>

      <div className={styles.intervention}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={checkedStates.weakening}
            onChange={() => handleCheckboxChange('weakening')}
            className={styles.checkbox}
          />
          Weakening of the Structure
        </label>
        {checkedStates.weakening && (
          <input
            type="text"
            value={inputValues.weakeningDetails}
            onChange={(e) => handleInputChange('weakeningDetails', e.target.value)}
            className={styles.input}
            placeholder="Enter weakening details"
          />
        )}
      </div>
    </div>
  );
};

export default BuildingInterventionsQuestion;
