import { Link } from "react-router-dom";
import styles from "./styles/BuildingList.module.css"

const BuildingList = ({ 
  buildings
}) => {
  
  return (
    <div className={styles.buildingsList}>
      {buildings.map((building) => (
        <div className={styles.buildingPreview} key={building.id}>
          <Link to={`/buildings/${building.id}`}>
            <h2>
              {building.address}
            </h2>
            <p>{building.city}  </p>
            <p>Added by: {building.author}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default BuildingList;

