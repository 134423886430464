import React, { useState, useEffect } from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import { Button } from '@mui/material';
import styles from './css/BuildingIrregularitiesQuestion.module.css';
import buildingShape1 from "../images/buildingShape/shape1.png";
import buildingShape2 from "../images/buildingShape/shape2.png";
import buildingShape3 from "../images/buildingShape/shape3.png";
import buildingShape4 from "../images/buildingShape/shape4.png";
import softFloorGF from "../images/structureIrregularities/softFloorGF.png";
import softFloorF from "../images/structureIrregularities/softFloorF.png";
import softFloorAT from "../images/structureIrregularities/softFloorAT.png";
import noIrregularities from "../images/structureIrregularities/noIrregularities.png";

const buildingShapeImageMappings = [
  { value: "Shape 1", image: buildingShape1 },
  { value: "Shape 2", image: buildingShape2 },
  { value: "Shape 3", image: buildingShape3 },
  { value: "Shape 4", image: buildingShape4 }
];

const structureIrregularitiesMappings = [
  { value: "No irregularities", image: noIrregularities },
  { value: "Soft Floor GF", image: softFloorGF },
  { value: "Soft Floor F", image: softFloorF },
  { value: "Soft Floor AT", image: softFloorAT }
];

const BuildingIrregularitiesQuestion = ({ clearError }) => {
  const [currentBShapeImageIndex, setCurrentBShapeImageIndex] = useState(0);
  const [currentSIrregularitiesImageIndex, setCurrentSIrregularitiesImageIndex] = useState(0);
  const { updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;
  const updateFunction = updateBuilding || updateFormData;

  useEffect(() => {
    if (building) {
      // If in edit mode, use the existing building data
      const initialBShapeIndex = buildingShapeImageMappings.findIndex(
        (mapping) => mapping.value === building.buildingShape
      );
      const initialSIrregularitiesIndex = structureIrregularitiesMappings.findIndex(
        (mapping) => mapping.value === building.structureIrregularities
      );

      if (initialBShapeIndex !== -1) {
        setCurrentBShapeImageIndex(initialBShapeIndex);
      }
      if (initialSIrregularitiesIndex !== -1) {
        setCurrentSIrregularitiesImageIndex(initialSIrregularitiesIndex);
      }
    } else {
      // If in create mode, set default values
      updateFunction('buildingShape', buildingShapeImageMappings[0].value);
      updateFunction('structureIrregularities', structureIrregularitiesMappings[0].value);
    }
  }, [building, updateFunction]);

  const handleBShapeNext = () => {
    const newIndex = (currentBShapeImageIndex + 1) % buildingShapeImageMappings.length;
    setCurrentBShapeImageIndex(newIndex);
    updateFunction('buildingShape', buildingShapeImageMappings[newIndex].value);
  };

  const handleBShapePrevious = () => {
    const newIndex = (currentBShapeImageIndex - 1 + buildingShapeImageMappings.length) % buildingShapeImageMappings.length;
    setCurrentBShapeImageIndex(newIndex);
    updateFunction('buildingShape', buildingShapeImageMappings[newIndex].value);
  };

  const handleSIrregularitiesNext = () => {
    const newIndex = (currentSIrregularitiesImageIndex + 1) % structureIrregularitiesMappings.length;
    setCurrentSIrregularitiesImageIndex(newIndex);
    updateFunction('structureIrregularities', structureIrregularitiesMappings[newIndex].value);
  };

  const handleSIrregularitiesPrevious = () => {
    const newIndex = (currentSIrregularitiesImageIndex - 1 + structureIrregularitiesMappings.length) % structureIrregularitiesMappings.length;
    setCurrentSIrregularitiesImageIndex(newIndex);
    updateFunction('structureIrregularities', structureIrregularitiesMappings[newIndex].value);
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 10</h4>
      <h2 className={styles.title}>Building shape in height</h2>
      <div className={styles.carousel}>
        <Button onClick={handleBShapePrevious}>{'<'}</Button>
        <img
          src={buildingShapeImageMappings[currentBShapeImageIndex].image}
          alt={buildingShapeImageMappings[currentBShapeImageIndex].value}
          className={styles.image}
        />
        <Button onClick={handleBShapeNext}>{'>'}</Button>
      </div>

      <div className={styles.separator} />

      <h2 className={styles.title}>Structure irregularities</h2>
      <div className={styles.carousel}>
        <Button onClick={handleSIrregularitiesPrevious}>{'<'}</Button>
        <img
          src={structureIrregularitiesMappings[currentSIrregularitiesImageIndex].image}
          alt={structureIrregularitiesMappings[currentSIrregularitiesImageIndex].value}
          className={styles.image}
        />
        <Button onClick={handleSIrregularitiesNext}>{'>'}</Button>
      </div>
    </div>
  );
};

export default BuildingIrregularitiesQuestion;
