import MultiStepForm from "./components/MultiStepForm";
import { FormDataProvider } from "./components/FormDataContext";

const Create = () => {
  return (
    <FormDataProvider>
      <MultiStepForm />
    </FormDataProvider>
  );
};

export default Create;
