import BuildingList from "./BuildingList";
import ExcelExportComponent from "./ExcelExportComponent";
import supabase from "./config/supabaseClient"
import { useEffect, useState } from "react";
import FilterList from "./components/FilterList";
import styles from './styles/Database.module.css';

const Database = () => {

  const [fetchError, setFetchError] = useState(null)
  const [buildings, setBuildings] = useState(null)
  const [isPending, setIsPending] = useState(true)
  const [orderBy] = useState('createdAt')
  const [filterListOpen, setFilterListOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);


  useEffect(() => {
    const fetchBuildings = async () => {
      const {data: buildings, error} = await supabase
        .from('buildings')
        .select()
        .order(orderBy, {ascending: false})

        if(error) {
          setFetchError('Could not fetch the data')
          setBuildings(null)
          console.log(error);
        }

        if(buildings) {
          setBuildings(buildings)
          setFetchError(null)
          setIsPending(false)
        }
    }

    fetchBuildings();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [orderBy])

  const handleBackgroundClick = () => {
    if (filterListOpen) {
      setFilterListOpen(false);
    }
  };

  const handleToggleFilterList = (event) => {
    event.stopPropagation();
    setFilterListOpen(!filterListOpen);
  };

  const [filters, setFilters] = useState({
    buildingPurpose: "",
    constructiveSystem: "",
    city: "",
    floorsAboveGround: "",
    address: ""
  });

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  const applyFilters = (building) => {
    for (const filterName in filters) {
      if (filters[filterName] && filters[filterName] !== "") {
        if (filterName === 'floorsAboveGround') {
          const rangeParts = filters[filterName].split('-');
          if (rangeParts.length !== 2) {
            continue;
          }
          const minFloor = parseInt(rangeParts[0]);
          const maxFloor = parseInt(rangeParts[1]);
          if (isNaN(minFloor) || isNaN(maxFloor) || building[filterName] < minFloor || building[filterName] > maxFloor) {
            return false;
          }
        } 
        else if(filterName === 'address'){
          const searchTerm = filters[filterName].toLowerCase();
          const buildingAddressLower = building.address.toLowerCase(); 
          if (!buildingAddressLower.includes(searchTerm)) {
            return false;
        }
        }
        else if (building[filterName] !== filters[filterName]) {
          return false;
        }
      }
    }
    return true;
  };

  const filteredBuildings = buildings
    ? buildings.filter(applyFilters)
    : [];

  const handleResetFilters = () => {
    setFilters({
      buildingPurpose: "",
      constructiveSystem: "",
      city: "",
      floorsAboveGround: "",
      address: "",
    });
  }


  return (
    <div className="home" onClick={handleBackgroundClick}>
      <div className={`filters ${filterListOpen ? "open" : ""}`}>
        <FilterList
          filterListOpen={filterListOpen}
          filters={filters}
          handleFilterChange={handleFilterChange}
          handleResetFilters={handleResetFilters}
        />
      </div>
      <div>
        {buildings && (
          <div className={styles.excelExport}>
            <ExcelExportComponent buildings={filteredBuildings.length > 0 ? filteredBuildings : buildings} />
            {isMobile && (
              <button className={styles.excelButton} onClick={handleToggleFilterList}>
                Filter Buildings
              </button>
            )}
          </div>
        )}
        {filteredBuildings.length > 0 ? (
          <BuildingList buildings={filteredBuildings} />
        ) : (
          <div className={styles.noBuildingsFound}>
            {/* {isPending && <h1>Loading...</h1>} */}
            {fetchError && <h1>{fetchError}</h1>}
            {!isPending && <h1>No buildings match the selected filters.</h1>}
          </div>
        )}
      </div>
    </div>
  );
};

export default Database;
