import { createContext, useContext, useState } from 'react';

export const FormDataContext = createContext();

export const useFormData = () => useContext(FormDataContext);

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    buildingPurpose: '',
    address: '',
    city: '',
    constructionYear: '',
    structureOfBuilding: '',
    floorsBelowGround: '',
    floorsAboveGround: '',
    basisShape: '',
    dimensions: [],
    buildingLocation: '',
    locationStringForm: '',
    groundFloorLevel: '',
    basementLevel: '',
    basementHeight: '',
    groundFloorHeight: '',
    floorHeight: '',
    atticHeight: '',
    totalBuildingHeight: '',
    roofType: '',
    roofMaterial: '',
    constructiveSystem: '',
    cover: '',
    buildingShape: '',
    structureIrregularities: '',
    extensionChecked: false,
    extensionDetails: '',
    upgradeChecked: false,
    upgradeDetails: '',
    strengtheningChecked: false,
    strengtheningDetails: '',
    weakeningChecked: false,
    weakeningDetails: '',
    hasStairs: false,
    hasElevators: false,
    hasEscalators: false,
    hasVerticalConveyorBelts: false,
    hasStandard: false,
    hasHorizontalConveyorBelts: false,
    structureMaintenance: '',
    liningMaintenance: '',
    waterPipeMaintenance: '',
    sewageMaintenance: '',
    drainingWaterFromTheRoofMaintenance: '',
    heatingAndCoolingInstallationsMaintenance: '',
    gasInstallationsMaintenance: '',
    electricalInstallationsMaintenance: '',
    fireProtectionMaintenance: '',
    securitySystemsMaintenance: '',
    structureDeficienciesChecked: false,
    structureDeficiencyDetails: '',
    liningDeficienciesChecked: false,
    liningDeficiencyDetails: '',
    installationsDeficienciesChecked: false,
    installationsDeficiencyDetails: '',
    hasSpecialNeedsUsers: false,
    averageAge: '',  
  });

  const updateFormData = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };

  return (
    <FormDataContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormDataContext.Provider>
  );
};
