import React from "react";
import './css/CustomInput.css';
import magnifyingGlassJPG from '../images/other/magnifyingGlass.jpg'

const CustomInput = ({ placeholder, value, onChange }) => {
    return (
      <div className="input-container">
        <img src={magnifyingGlassJPG} alt="Search" className="search-icon" />
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="select-dropdown"
        />
      </div>
    );
  };
  
  export default CustomInput;