import React, { useState, useEffect } from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingDeficienciesQuestion.module.css';

const BuildingDeficienciesQuestion = ({ clearError }) => {
  const { formData, updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building || {};
  const updateBuilding = editContextData?.updateBuilding;
  const updateFunction = updateBuilding || updateFormData;

  const initialCheckedStates = {
    structure: Boolean(building?.structureDeficiencyDetails || formData?.structureDeficiencyDetails),
    lining: Boolean(building?.liningDeficiencyDetails || formData?.liningDeficiencyDetails),
    installations: Boolean(building?.installationsDeficiencyDetails || formData?.installationsDeficiencyDetails),
    specialNeeds: Boolean(building?.hasSpecialNeedsUsers || formData?.hasSpecialNeedsUsers),
  };

  const [checkedStates, setCheckedStates] = useState(initialCheckedStates);

  const initialInputValues = {
    structureDeficiencyDetails: building?.structureDeficiencyDetails || formData?.structureDeficiencyDetails || '',
    liningDeficiencyDetails: building?.liningDeficiencyDetails || formData?.liningDeficiencyDetails || '',
    installationsDeficiencyDetails: building?.installationsDeficiencyDetails || formData?.installationsDeficiencyDetails || '',
  };

  const [inputValues, setInputValues] = useState(initialInputValues);
  const averageAge = building?.averageAge || formData?.averageAge || '';

  useEffect(() => {
    updateFunction('structureDeficiencyDetails', inputValues.structureDeficiencyDetails);
    updateFunction('liningDeficiencyDetails', inputValues.liningDeficiencyDetails);
    updateFunction('installationsDeficiencyDetails', inputValues.installationsDeficiencyDetails);
  }, [checkedStates, inputValues, updateFunction]);

  const handleCheckboxChange = (field) => {
    setCheckedStates((prev) => {
      const newState = !prev[field];
      if (!newState) {
        setInputValues((prevValues) => ({
          ...prevValues,
          [`${field}DeficiencyDetails`]: '',
        }));
      }
      return {
        ...prev,
        [field]: newState,
      };
    });
    clearError();
  };

  const handleInputChange = (field, value) => {
    setInputValues((prev) => ({
      ...prev,
      [field]: value,
    }));
    clearError();
  };

  const handleAverageAgeInput = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      updateFunction('averageAge', value);
    }
  };

  const handleSpecialNeedsChange = () => {
    const newValue = !checkedStates.specialNeeds;
    setCheckedStates((prev) => ({
      ...prev,
      specialNeeds: newValue,
    }));
    updateFunction('hasSpecialNeedsUsers', newValue);
    clearError();
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 16</h4>
      <h2 className={styles.title}>Noted deficiencies on building (optional)</h2>

      <div className={styles.intervention}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={checkedStates.structure}
            onChange={() => handleCheckboxChange('structure')}
            className={styles.checkbox}
          />
          Structure deficiencies
        </label>
        {checkedStates.structure && (
          <input
            type="text"
            value={inputValues.structureDeficiencyDetails}
            onChange={(e) => handleInputChange('structureDeficiencyDetails', e.target.value)}
            className={styles.input}
            placeholder="Enter deficiency details"
          />
        )}
      </div>

      <div className={styles.intervention}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={checkedStates.lining}
            onChange={() => handleCheckboxChange('lining')}
            className={styles.checkbox}
          />
          Lining deficiencies
        </label>
        {checkedStates.lining && (
          <input
            type="text"
            value={inputValues.liningDeficiencyDetails}
            onChange={(e) => handleInputChange('liningDeficiencyDetails', e.target.value)}
            className={styles.input}
            placeholder="Enter deficiency details"
          />
        )}
      </div>

      <div className={styles.intervention}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={checkedStates.installations}
            onChange={() => handleCheckboxChange('installations')}
            className={styles.checkbox}
          />
          Installation deficiencies
        </label>
        {checkedStates.installations && (
          <input
            type="text"
            value={inputValues.installationsDeficiencyDetails}
            onChange={(e) => handleInputChange('installationsDeficiencyDetails', e.target.value)}
            className={styles.input}
            placeholder="Enter deficiency details"
          />
        )}
      </div>

      <h2 className={styles.averageAgeTitle}>Average age of residents</h2>
      <input
        type="text"
        inputMode="numeric"
        value={averageAge}
        pattern="[0-9]*"
        onChange={handleAverageAgeInput}
        className={styles.averageAge}
      />

      <h2 className={styles.title}>Are users with special needs present?</h2>

      <div className={styles.intervention}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={checkedStates.specialNeeds}
            onChange={handleSpecialNeedsChange}
            className={styles.checkbox}
          />
          Special needs users
        </label>
      </div>
    </div>
  );
};

export default BuildingDeficienciesQuestion;
