import { useParams } from "react-router-dom";
import { useNavigate, Link } from "react-router-dom";
import supabase from "./config/supabaseClient";
import { useState, useCallback } from "react";
import { useEffect } from "react";
import styles from "./styles/BuildingDetails.module.css";
import MaintenanceGrade from "./components/MaintenanceGrade";

import basisShape1 from "./images/basisShape/shape1.png";
import basisShape2 from "./images/basisShape/shape2.png";
import basisShape3 from "./images/basisShape/shape3.png";
import basisShape4 from "./images/basisShape/shape4.png";
import basisShape5 from "./images/basisShape/shape5.png";
import basisShape6 from "./images/basisShape/shape6.png";
import buildingShape1 from "./images/buildingShape/shape1.png";
import buildingShape2 from "./images/buildingShape/shape2.png";
import buildingShape3 from "./images/buildingShape/shape3.png";
import buildingShape4 from "./images/buildingShape/shape4.png";
import softFloorGF from "./images/structureIrregularities/softFloorGF.png";
import softFloorF from "./images/structureIrregularities/softFloorF.png";
import softFloorAT from "./images/structureIrregularities/softFloorAT.png";
import noIrregularities from "./images/structureIrregularities/noIrregularities.png";

const basisImageMappings = [
  { value: "Shape 1", image: basisShape1 },
  { value: "Shape 2", image: basisShape2 },
  { value: "Shape 3", image: basisShape3 },
  { value: "Shape 4", image: basisShape4 },
  { value: "Shape 5", image: basisShape5 },
  { value: "Shape 6", image: basisShape6 },
];

const buildingShapeImageMappings = [
  { value: "Shape 1", image: buildingShape1 },
  { value: "Shape 2", image: buildingShape2 },
  { value: "Shape 3", image: buildingShape3 },
  { value: "Shape 4", image: buildingShape4 },
];

const structureIrregularitiesMappings = [
  { value: "No irregularities", image: noIrregularities },
  { value: "Soft Floor GF", image: softFloorGF },
  { value: "Soft Floor F", image: softFloorF },
  { value: "Soft Floor AT", image: softFloorAT },
];

const BuildingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fetchError, setFetchError] = useState(null);
  const [building, setBuilding] = useState(null);
  const [media, setMedia] = useState(null);
  const [address, setAddress] = useState(null)

  const adminID = process.env.REACT_APP_ADMIN_ID;
  const user = JSON.parse(localStorage.getItem("user")).user;


  const handleDelete = async () => {
    const { data, error } = await supabase.from("buildings").delete().eq("id", building.id).select();

    if (error) {
      console.log(error);
    }
    if (data) {
      navigate("/");
    }
  };

  
  useEffect(() => {
    const fetchBuildings = async () => {
      const { data: buildingData, error } = await supabase.from("buildings").select().eq("id", id).single(); // Fetch a single building based on its ID
      
      if (error) {
        setFetchError("Could not fetch the data");
        setBuilding(null);
        console.log(error);
      }
      
      if (buildingData) {
        setBuilding(buildingData);
        setFetchError(null);
      }
    };
    fetchBuildings();
  }, [id]);
  
  const normalizeAddress = (text) => {
    return text.replace("Š", "S").replace("š", "s").replace("Č", "C").replace("č", "c").replace("Ć", "C").replace("ć", "c").replace("Ð", "D").replace("đ", "d").replace("Dž", "Dz").replace("dž", "dz").replace("Ž", "Z").replace("ž", "z");
  }; 

  const getFile = useCallback(async () => {
    if (building) {
      setAddress(normalizeAddress(building.address))
      const { data, error } = await supabase.storage.from("uploads").list(`${address}`);
      if (data) {
        setMedia(data);
      }
      if (error) {
        console.error("Error fetching media:", error);
      }
    }
  }, [building, setMedia, address]);

  useEffect(() => {
    getFile();
  }, [building, getFile]);

  // console.log(media);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  };

  return (
    <div className={styles.buildingDetails}>
      {fetchError && <div> {fetchError} </div>}
      {building && (
        <article>
            <div className={styles.buttonContainer}>
              {user && (user.id === building.author || user.id === adminID) && (
                <>
                  <Link to={`/buildings/update/${id}`}>
                    <button className={styles.button}>Edit</button>
                  </Link>
                  {user.id === adminID && (
                    <button className={styles.deleteButton} onClick={handleDelete}>
                      Delete
                    </button>
                  )}
                </>
              )}
            </div>
          <header className={styles.detailsHeader}>
            <h2>{building.address}</h2>
          </header>

          <div className={styles.subHeader}>
            <p>Added by: {building.author}</p>
            <p>Created at: {formatTimestamp(building.createdAt)} </p>
            <p>
              Address: {building.address} , {building.city}
            </p>
          </div>

          <section className={styles.detailsInfo}>
            {media?.length > 0 ? <div>
              <h2>Images and PDF</h2>
              <div className={styles.shapes}>
                  {media?.map((media) => {
                    return (
                      <div>
                        <img
                          src={`https://nyhfwymlixgprmqazsvh.supabase.co/storage/v1/object/public/uploads/${address}/${media.name}`}
                          alt="slika"
                          width={200}
                          height={200}
                          style={{
                            display: media.name
                              .split(".")
                              .pop()
                              .toLowerCase()
                              .match(/^(jpg|jpeg|png|webp|gif|bmp|avif|tiff|psd|eps|svg|ai|indd|xcf|raw|heic)$/)
                              ? ""
                              : "none",
                          }}
                        />
                        {media.name.split(".").pop().toLowerCase() === "pdf" && (
                          <>
                            <a href={`https://nyhfwymlixgprmqazsvh.supabase.co/storage/v1/object/public/uploads/${address}/${media.name}`}>
                              <img src="/pdf-icon.jpeg" alt="PDF icon" height={200} width={200} />
                            </a>
                          </>
                        )}
                      </div>
                    );
                  })
                }
              </div>
            </div> : null}
            <div>
              <h2>Important Shapes</h2>
              <div className={styles.shapes}>
                <div>
                  <h3>Building basis shape</h3>
                  <img src={basisImageMappings.find((item) => item.value === building.basisShape)?.image} alt={building.basisShape} />
                </div>
                <div>
                  <h3>Building shape in height</h3>
                  <img src={buildingShapeImageMappings.find((item) => item.value === building.buildingShape)?.image} alt={building.buildingShape} />
                </div>
                <div>
                  <h3>Structure irregularities</h3>
                  <img src={structureIrregularitiesMappings.find((item) => item.value === building.structureIrregularities)?.image} alt={building.structureIrregularities} />
                </div>
              </div>
            </div>

            <div className={styles.infoSection}>
              <h2>Important Data</h2>
              <table>
                <tbody>
                  <tr>
                    <td>Building purpose</td>
                    <td>{building.buildingPurpose}</td>
                  </tr>
                  <tr>
                    <td>GPS</td>
                    <td>{building.locationStringForm ? building.locationStringForm : "Undefined"}</td>
                  </tr>
                  <tr>
                    <td>Structure of building</td>
                    <td>{building.structureOfBuilding}</td>
                  </tr>
                  <tr>
                    <td>Number of floors above ground</td>
                    <td>{building.floorsAboveGround}</td>
                  </tr>
                  {building.floorsBelowGround > 0 && (
                    <tr>
                      <td>Number of floors below ground</td>
                      <td>{building.floorsBelowGround}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Construction year</td>
                    <td>{building.constructionYear}</td>
                  </tr>
                  <tr>
                    <td>Basis shape dimensions</td>
                    <td>{building.dimensions.join(",")}</td>
                  </tr>
                  <tr>
                    <td>Building location</td>
                    <td>{building.buildingLocation}</td>
                  </tr>
                  {building.basementLevel > 0 && (
                    <tr>
                      <td>Basement level</td>
                      <td>{building.basementLevel}</td>
                    </tr>
                  )}
                  {building.basementHeight > 0 && (
                    <tr>
                      <td>Basement height</td>
                      <td>{building.basementHeight}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Ground floor level</td>
                    <td>{building.groundFloorLevel}</td>
                  </tr>
                  <tr>
                    <td>Ground floor height</td>
                    <td>{building.groundFloorHeight}</td>
                  </tr>
                  <tr>
                    <td>Floor height</td>
                    <td>{building.floorHeight}</td>
                  </tr>
                  {building.atticHeight > 0 && (
                    <tr>
                      <td>Attic height</td>
                      <td>{building.atticHeight}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Total building height</td>
                    <td>{building.totalBuildingHeight}</td>
                  </tr>
                  <tr>
                    <td>Roof type</td>
                    <td>{building.roofType}</td>
                  </tr>
                  <tr>
                    <td>Roof material</td>
                    <td>{building.roofMaterial}</td>
                  </tr>
                  <tr>
                    <td>Cover</td>
                    <td>{building.cover}</td>
                  </tr>
                  <tr>
                    <td>Constructive system</td>
                    <td>{building.constructiveSystem}</td>
                  </tr>
                  <tr>
                    <td>Average age of residents</td>
                    <td>{building.averageAge}</td>
                  </tr>
                  <tr>
                    <td>Has special needs users</td>
                    <td>{String(building.hasSpecialNeedsUsers)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={styles.infoSection}>
              <h2>Interventions on building</h2>
              <table>
                <tbody>
                  <tr>
                    <td>Extension</td>
                    <td>{building.extensionDetails !== "" ? building.extensionDetails : "No noted interventions"}</td>
                  </tr>
                  <tr>
                    <td>Upgrade</td>
                    <td>{building.upgradeDetails !== "" ? building.upgradeDetails : "No noted interventions"}</td>
                  </tr>
                  <tr>
                    <td>Strengthening</td>
                    <td>{building.strengtheningDetails !== "" ? building.strengtheningDetails : "No noted interventions"}</td>
                  </tr>
                  <tr>
                    <td>Weakening</td>
                    <td>{building.weakeningDetails !== "" ? building.weakeningDetails : "No noted interventions"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={styles.infoSection}>
              <h2>Deficiencies on building</h2>
              <table>
                <tbody>
                  <tr>
                    <td>Structure</td>
                    <td>{building.structureDeficiencyDetails !== "" ? building.structureDeficiencyDetails : "No noted deficiencies"}</td>
                  </tr>
                  <tr>
                    <td>Lining</td>
                    <td>{building.liningDeficiencyDetails !== "" ? building.liningDeficiencyDetails : "No noted deficiencies"}</td>
                  </tr>
                  <tr>
                    <td>Installations</td>
                    <td>{building.installationsDeficiencyDetails !== "" ? building.installationsDeficiencyDetails : "No noted deficiencies"}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={styles.infoSection}>
              <h2>Communications</h2>
              <h3> Vertical </h3>
              <div>
                {building.hasStairs && <p>Stairs</p>}
                {building.hasElevators && <p>Elevators</p>}
                {building.hasEscalators && <p>Escalators</p>}
                {building.hasVerticalConveyorBelts && <p>Vertical conveyor belts</p>}
                {!building.hasStairs && !building.hasElevators && !building.hasEscalators && !building.hasVerticalConveyorBelts && <p>No present vertical communications</p>}
              </div>
              <h3> Horizontal </h3>
              <div>
                {building.hasStandard && <p>Standard</p>}
                {building.hasHorizontalConveyorBelts && <p>Horizontal conveyor belts</p>}
                {!building.hasStandard && !building.hasHorizontalConveyorBelts && <p>No present horizontal communications</p>}
              </div>
            </div>

            {building.structureMaintenance || building.liningMaintenance || building.waterPipeMaintenance || building.sewageMaintenance || building.drainingWaterFromTheRoofMaintenance || building.heatingAndCoolingInstallationsMaintenance || building.gasInstallationsMaintenance || building.electricalInstallationsMaintenance || building.fireProtectionMaintenance || building.securitySystemsMaintenance ?
              <div className={styles.infoSection}>
              <h2>Building maintenance (1-5)</h2>
              {building.structureMaintenance ? <MaintenanceGrade title="Structure maintenance" grade={building.structureMaintenance} /> : null}
              {building.liningMaintenance ? <MaintenanceGrade title="Lining maintenance" grade={building.liningMaintenance} /> : null}
              {building.waterPipeMaintenance ? <MaintenanceGrade title="Water pipe maintenance" grade={building.waterPipeMaintenance} /> : null}
              {building.sewageMaintenance ? <MaintenanceGrade title="Sewage maintenance" grade={building.sewageMaintenance} /> : null}
              {building.drainingWaterFromTheRoofMaintenance ? <MaintenanceGrade title="Draining water from the roof maintenance" grade={building.drainingWaterFromTheRoofMaintenance} /> : null}
              {building.heatingAndCoolingInstallationsMaintenance ? <MaintenanceGrade title="Heating/Cooling installations maintenance" grade={building.heatingAndCoolingInstallationsMaintenance} /> : null}
              {building.gasInstallationsMaintenance ? <MaintenanceGrade title="Gas installations maintenance" grade={building.gasInstallationsMaintenance} /> : null}
              {building.electricalInstallationsMaintenance ? <MaintenanceGrade title="Electrical installations maintenance" grade={building.electricalInstallationsMaintenance} /> : null}
              {building.fireProtectionMaintenance ? <MaintenanceGrade title="Fire protection maintenance" grade={building.fireProtectionMaintenance} /> : null}
              {building.securitySystemsMaintenance ? <MaintenanceGrade title="Security system maintenance" grade={building.securitySystemsMaintenance} /> : null}
            </div> : null}
          </section>
        </article>
      )}
    </div>
  );
};

export default BuildingDetails;
