import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '@mui/material';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingBasisShapeQuestion.module.css';

import basisShape1 from '../images/basisShape/shape1.png';
import basisShape2 from '../images/basisShape/shape2.png';
import basisShape3 from '../images/basisShape/shape3.png';
import basisShape4 from '../images/basisShape/shape4.png';
import basisShape5 from '../images/basisShape/shape5.png';
import basisShape6 from '../images/basisShape/shape6.png';

const basisImageMappings = [
  { value: 'Shape 1', image: basisShape1 },
  { value: 'Shape 2', image: basisShape2 },
  { value: 'Shape 3', image: basisShape3 },
  { value: 'Shape 4', image: basisShape4 },
  { value: 'Shape 5', image: basisShape5 },
  { value: 'Shape 6', image: basisShape6 }
];

const dimensionMappings = [
  { value: 'Shape 1', labels: ['a '] },
  { value: 'Shape 2', labels: ['a ', 'b '] },
  { value: 'Shape 3', labels: ['a ', 'b', 'a1 ', 'b1'] },
  { value: 'Shape 4', labels: ['a1', 'a2', 'b1', 'b2'] },
  { value: 'Shape 5', labels: ['a1', 'a2', 'a3', 'b1', 'b2', 'b3'] },
  { value: 'Shape 6', labels: ['a1', 'a2', 'a3', 'a4', 'a5', 'b1', 'b2', 'b3', 'b4', 'b5'] }
];

const BuildingBasisShapeQuestion = ({ clearError, setValidationError, passValidateDimensions }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentDimensions, setCurrentDimensions] = useState([]);
  const { formData, updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;
  const updateFunction = updateBuilding || updateFormData;

  const basisShape = building?.basisShape || formData.basisShape;
  const dimensions = building?.dimensions || formData.dimensions;

  useEffect(() => {
    if (basisShape) {
      const initialImageIndex = basisImageMappings.findIndex(
        (mapping) => mapping.value === basisShape
      );
      if (initialImageIndex !== -1) {
        setCurrentImageIndex(initialImageIndex);
      }
    } else {
      updateFunction('basisShape', basisImageMappings[0].value);
    }

    if (dimensions) {
      setCurrentDimensions(dimensions);
    }
  }, [basisShape, dimensions, updateFunction]);

  const handleNext = () => {
    const newIndex = (currentImageIndex + 1) % basisImageMappings.length;
    setCurrentImageIndex(newIndex);
    setCurrentDimensions([]);
    updateFunction('dimensions', []);
    updateFunction('basisShape', basisImageMappings[newIndex].value);
  };

  const handlePrevious = () => {
    const newIndex = (currentImageIndex - 1 + basisImageMappings.length) % basisImageMappings.length;
    setCurrentImageIndex(newIndex);
    setCurrentDimensions([]);
    updateFunction('dimensions', []);
    updateFunction('basisShape', basisImageMappings[newIndex].value);
  };

  const handleDimensionChange = (index, value) => {
    const parsedValue = parseFloat(value.replace(/[^0-9/.]/g, ""));
    if (!isNaN(parsedValue)) {
      const updatedDimensions = [...currentDimensions];
      updatedDimensions[index] = value;
      setCurrentDimensions(updatedDimensions);
      updateFunction("dimensions", updatedDimensions);
    }
    else {
      setCurrentDimensions("");
      updateFunction("dimensions", "");
    }
  };

  const validateDimensions = useCallback(() => {
    const currentShape = basisImageMappings[currentImageIndex].value;
    const selectedShape = dimensionMappings.find((option) => option.value === currentShape);
    
    if (!selectedShape) return false;
  
    return selectedShape.labels.every((_, index) => {
      const dimensionValue = currentDimensions[index];
      return typeof dimensionValue === 'string' && dimensionValue.trim() !== '';
    });
  }, [currentImageIndex, currentDimensions]);
  

  useEffect(() => {
    if (setValidationError) {
      setValidationError(!validateDimensions());
    }
    if (passValidateDimensions) {
      passValidateDimensions(() => validateDimensions
    );
  }
}, [currentDimensions, currentImageIndex, setValidationError, validateDimensions, passValidateDimensions]);

useEffect(() => {
  if (setValidationError && validateDimensions()) {
    clearError(); 
  }
}, [currentDimensions, currentImageIndex, clearError, setValidationError, validateDimensions]);

const renderDimensions = () => {
  const currentShape = basisImageMappings[currentImageIndex].value;
  const selectedShape = dimensionMappings.find((option) => option.value === currentShape);
  if (!selectedShape) return null;

  return selectedShape.labels.map((label, index) => (
    <div key={label} className={styles.gridItem}>
      <label className={styles.label}>{label}:</label>
      <input 
        type="number"
        inputMode="number" 
        value={currentDimensions[index] || ''} 
        pattern="[0-9]+"
        onChange={(e) => handleDimensionChange(index, e.target.value)} 
        className={styles.input} />
    </div>
  ));
};

return (
  <div className={styles.container}>
    <div className={styles.topSection}>
      <h4 className={styles.questionNumber}>Question 4</h4>
      <h2 className={styles.title}>Building basis shape</h2>
      <div className={styles.carousel}>
        <Button onClick={handlePrevious}>{'<'}</Button>
        <img
          src={basisImageMappings[currentImageIndex].image}
          alt={basisImageMappings[currentImageIndex].value}
          className={styles.image}
        />
        <Button onClick={handleNext}>{'>'}</Button>
      </div>
      <div className={styles.separator}></div>
    </div>
    <div className={styles.dimensionsSection}>
      <div className={styles.dimensionsLabelContainer}>
        <h3 className={styles.dimensionsLabel}>Dimensions:</h3>
      </div>
      <div className={styles.separator}></div>
      <div className={styles.dimensionsGridContainer}>
        <div className={styles.dimensionsGrid}>
          {renderDimensions()}
        </div>
      </div>
    </div>
  </div>
);
};

export default BuildingBasisShapeQuestion;
