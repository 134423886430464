import React, { useEffect, useState } from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingCommunicationsQuestion.module.css'

const BuildingCommunicationsQuestion = () => {
  const { formData, updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;
  const updateFunction = updateBuilding || updateFormData;

  // Initialize checkbox states explicitly
  const [checkedStates, setCheckedStates] = useState({
    hasStairs: building?.hasStairs || formData?.hasStairs || false,
    hasElevators: building?.hasElevators || formData?.hasElevators || false,
    hasEscalators: building?.hasEscalators || formData?.hasEscalators || false,
    hasVerticalConveyorBelts: building?.hasVerticalConveyorBelts || formData?.hasVerticalConveyorBelts || false,
    hasStandard: building?.hasStandard || formData?.hasStandard || false,
    hasHorizontalConveyorBelts: building?.hasHorizontalConveyorBelts || formData?.hasHorizontalConveyorBelts || false,
  });

  useEffect(() => {
    if (building) {
      setCheckedStates({
        hasStairs: building.hasStairs || false,
        hasElevators: building.hasElevators || false,
        hasEscalators: building.hasEscalators || false,
        hasVerticalConveyorBelts: building.hasVerticalConveyorBelts || false,
        hasStandard: building.hasStandard || false,
        hasHorizontalConveyorBelts: building.hasHorizontalConveyorBelts || false,
      });
    }
  }, [building]);

  const handleCheckboxChange = (field) => {
    setCheckedStates((prev) => {
      const newState = !prev[field];
      updateFunction(field, newState);
      return { ...prev, [field]: newState };
    });
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 12</h4>
      <h2 className={styles.title}>Vertical communications in building (optional)</h2>
      <label className={styles.label}>
        <input
          type="checkbox"
          checked={checkedStates.hasStairs}
          onChange={() => handleCheckboxChange('hasStairs')}
          className={styles.checkbox}
        />
        Stairs
      </label>
      <label className={styles.label}>
        <input
          type="checkbox"
          checked={checkedStates.hasElevators}
          onChange={() => handleCheckboxChange('hasElevators')}
          className={styles.checkbox}
        />
        Elevators
      </label>
      <label className={styles.label}>
        <input
          type="checkbox"
          checked={checkedStates.hasEscalators}
          onChange={() => handleCheckboxChange('hasEscalators')}
          className={styles.checkbox}
        />
        Escalators
      </label>
      <label className={styles.label}>
        <input
          type="checkbox"
          checked={checkedStates.hasVerticalConveyorBelts}
          onChange={() => handleCheckboxChange('hasVerticalConveyorBelts')}
          className={styles.checkbox}
        />
        Conveyor belts
      </label>

      <div className={styles.separator} />

      <h2 className={styles.title}>Horizontal communications in building (optional)</h2>
      <label className={styles.label}>
        <input
          type="checkbox"
          checked={checkedStates.hasStandard}
          onChange={() => handleCheckboxChange('hasStandard')}
          className={styles.checkbox}
        />
        Standard
      </label>
      <label className={styles.label}>
        <input
          type="checkbox"
          checked={checkedStates.hasHorizontalConveyorBelts}
          onChange={() => handleCheckboxChange('hasHorizontalConveyorBelts')}
          className={styles.checkbox}
        />
        Conveyor belts
      </label>
    </div>
  );
};

export default BuildingCommunicationsQuestion;
