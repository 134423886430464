import React from 'react';
import './css/MaintenanceGrade.css';

const MaintenanceGrade = ({ title, grade }) => {
  const circles = Array(5).fill(0).map((_, i) => (
    <div
      key={i}
      className={`circle ${i < grade ? 'filled' : ''}`}
    ></div>
  ));

  return (
    <div className="maintenance-grade">
      <p>{title}</p>
      <div className="circles-container">
        {circles}
      </div>
    </div>
  );
};

export default MaintenanceGrade;
