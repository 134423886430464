import Database from "./Database";
import Navbar from "./Navbar";
import Create from "./Create";
import Update from "./Update";
import LandingPage from "./LandingPage"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BuildingDetails from "./BuildingDetails";
import Login from "./Login";
import { useState } from "react";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PageNotFound from "./PageNotFound";
import AboutUs from "./AboutUs";

function App() {
  const [user, setUser] = useState(localStorage.getItem("user"));

  return (
    <Router>
      <div className="app">
        <div className="navbar">
          {user ? <Navbar setUser={setUser} /> : null}
        </div>
        <div className="content">
          <Routes>
            <Route path="*" element={<PageNotFound user={user} />} />
            {user ? (
              <Route element={<PrivateRoute user={user} />}>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="/create" element={<Create />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/buildings/:id" element={<BuildingDetails />} />
                <Route path="/buildings/update/:id" element={<Update />} />
                <Route path="/database" element = {<Database/>} />
              </Route>
            ) : (
              <Route element={<PublicRoute user={user} />}>
                <Route exact path="/login" element={<Login setUser={setUser} />} />
              </Route>
            )}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
