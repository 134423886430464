import React, { useState, useEffect, useCallback } from 'react';
import { useEditContext } from './components/EditContext';
import BuildingPurposeQuestion from './questions/BuildingPurposeQuestion';
import CityAddresQuestion from './questions/CityAddressQuestion';
import styles from './styles/EditBuildingDetails.module.css';
import StructureOfBuildingQuestion from './questions/StructureOfBuildingQuestion';
import BuildingBasisShapeQuestion from './questions/BuildingBasisShapeQuestion';
import BuildingLocationQuestion from './questions/BuildingLocationQuestion';
import BuildingRoofDetailsQuestion from './questions/BuildingRoofDetailsQuestion';
import ConstructiveSystemQuestion from './questions/ConstructiveSystemQuestion';
import BuildingCoverQuestion from './questions/BuildingCoverQuestion'
import HeightQuestion from './questions/HeightQuestion';
import BuildingIrregularitiesQuestion from './questions/BuildingIrregularitiesQuestion'
import BuildingInterventionsQuestion from './questions/BuildingInterventionsQuestion';
import BuildingCommunicationsQuestion from './questions/BuildingCommunicationsQuestion';
import BuildingMaintenance1Question from './questions/BuildingMaintenance1Question';
import BuildingMaintenance2Question from './questions/BuildingMaintenance2Question';
import BuildingMaintenance3Question from './questions/BuildingMaintenance3Question';
import BuildingDeficienciesQuestion from './questions/BuildingDeficienciesQuestion';
// import ImageUploadQuestion from "./questions/ImageUploadQuestion";
import supabase from './config/supabaseClient';


import basisShape1 from './images/basisShape/shape1.png';
import basisShape2 from './images/basisShape/shape2.png';
import basisShape3 from './images/basisShape/shape3.png';
import basisShape4 from './images/basisShape/shape4.png';
import basisShape5 from './images/basisShape/shape5.png';
import basisShape6 from './images/basisShape/shape6.png';
import buildingShape1 from "./images/buildingShape/shape1.png"
import buildingShape2 from "./images/buildingShape/shape2.png"
import buildingShape3 from "./images/buildingShape/shape3.png"
import buildingShape4 from "./images/buildingShape/shape4.png"
import softFloorGF from "./images/structureIrregularities/softFloorGF.png"
import softFloorF from "./images/structureIrregularities/softFloorF.png"
import softFloorAT from "./images/structureIrregularities/softFloorAT.png"
import noIrregularities from "./images/structureIrregularities/noIrregularities.png";
// import { component } from "react-select";

const basisImageMappings = [
  { value: 'Shape 1', image: basisShape1 },
  { value: 'Shape 2', image: basisShape2 },
  { value: 'Shape 3', image: basisShape3 },
  { value: 'Shape 4', image: basisShape4 },
  { value: 'Shape 5', image: basisShape5 },
  { value: 'Shape 6', image: basisShape6 }
];

const buildingShapeImageMappings = [
  { value: "Shape 1", image: buildingShape1 },
  { value: "Shape 2", image: buildingShape2 },
  { value: "Shape 3", image: buildingShape3 },
  { value: "Shape 4", image: buildingShape4 }
]

const structureIrregularitiesMappings = [
    { value: "No irregularities", image: noIrregularities },
    { value: "Soft Floor GF", image: softFloorGF },
    { value: "Soft Floor F", image: softFloorF },
    { value: "Soft Floor AT", image: softFloorAT }
];

const normalizeAddress = (text) => {
    return text.replace("Š", "S").replace("š", "s").replace("Č", "C").replace("č", "c").replace("Ć", "C").replace("ć", "c").replace("Ð", "D").replace("đ", "d").replace("Dž", "Dz").replace("dž", "dz").replace("Ž", "Z").replace("ž", "z");
  }; 

const EditBuildingDetails = () => {
  const { building, saveBuildingData } = useEditContext();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState('');
  const [validateDimensions, setValidateDimensions] = useState(() => () => true);
  const [media, setMedia] = useState(null);
  const [address, setAddress] = useState(null);

  const getFile = useCallback(async () => {
    if (building) {
      setAddress(normalizeAddress(building.address));
      const { data, error } = await supabase.storage.from("uploads").list(`${address}`);
      if (data) {
        setMedia(data);
      }
      if (error) {
        console.error("Error fetching media:", error);
      }
    }
  }, [building, setMedia, address]);

  useEffect(() => {
    if (building) {
      // getFile();
      setQuestions([
        { id: 1, component: BuildingPurposeQuestion, fields: [
          { name: 'Building Purpose:', value: building.buildingPurpose }] },
        { id: 2, component: CityAddresQuestion, fields: [
          { name: 'Address:', value: building.address }, 
          { name: 'City:', value: building.city }, 
          { name: 'Construction year:', value: building.constructionYear }] },
        { id: 3, component: StructureOfBuildingQuestion, fields: [
          {name: 'Structure Of Building:', value: building.structureOfBuilding},
          {name: 'Floors above ground:', value: building.floorsAboveGround},
          ...(building.floorsBelowGround ? [{ name: 'Floors below ground:', value: building.floorsBelowGround }] : [])] },
        { id: 4, component: BuildingBasisShapeQuestion, fields: [
          { name: 'Basis Shape:', image: basisImageMappings.find(mapping => mapping.value === building.basisShape)?.image},
          { name: 'Dimensions:', value: building.dimensions.join(', ') }
        ]},
        { id: 5, component: BuildingLocationQuestion, fields: [
          { name: 'Building location:', value: building.buildingLocation },
          {name: "GPS:" ,value: building.locationStringForm} ] },
        { id: 6, component: HeightQuestion, fields: [
          ...(building.structureOfBuilding.includes("Basement") ? [{ name: 'Basement level:', value: building.basementLevel ? building.basementLevel : 'MISSING' }] : []),
          ...(building.structureOfBuilding.includes("Basement") ? [{ name: 'Basement height:', value: building.basementHeight ? building.basementHeight : 'MISSING' }] : []), 
          { name: 'Ground floor level:', value: building.groundFloorLevel },
          { name: 'Ground floor height:', value: building.groundFloorHeight },
          { name: 'Floor height:', value: building.floorHeight }, 
          ...(building.structureOfBuilding.includes("Attic") ? [{ name: 'Attic height:', value: building.atticHeight ? building.atticHeight : 'MISSING' }] : []), 
          { name: 'Total building height:', value: building.totalBuildingHeight }] },
        { id: 7, component: BuildingRoofDetailsQuestion, fields: [
          { name: 'Roof type:', value: building.roofType }, 
          { name: 'Roof material construction:', value: building.roofMaterial }, ] },
        { id: 8, component: ConstructiveSystemQuestion, fields: [
          { name: 'Constructive system:', value: building.constructiveSystem }] },
        { id: 9, component: BuildingCoverQuestion, fields: [
          { name: 'Cover:', value: building.cover }] },
        { id: 10, component: BuildingIrregularitiesQuestion, fields: [
          { name: 'Building shape in height:', image: buildingShapeImageMappings.find(mapping => mapping.value === building.buildingShape)?.image},
          { name: 'Structure irregularities:', image: structureIrregularitiesMappings.find(mapping => mapping.value === building.structureIrregularities)?.image},
        ]},
        { id: 11, component: BuildingInterventionsQuestion, fields: [
          ...(building.extensionDetails ? [{ name: 'Extension details:', value: building.extensionDetails }] : []) ,
          ...(building.upgradeDetails ? [{ name: 'Upgrade details:', value: building.upgradeDetails }] : []) ,
          ...(building.strengtheningDetails ? [{ name: 'Strengthening details:', value: building.strengtheningDetails }] : []) ,
          ...(building.weakeningDetails ? [{ name: 'Weakening details:', value: building.weakeningDetails }] : []),
          ...(building.extensionDetails || building.upgradeDetails || building.strengtheningDetails || building.weakeningDetails ? [] : [{ name: 'No interventions were noted', value: ' ' }]),
        ]},
        { id: 12, component: BuildingCommunicationsQuestion, fields: [
          { name: 'Stairs:', value: building.hasStairs ? '✅' : '❌' },
          { name: 'Elevators:', value: building.hasElevators ? '✅' : '❌' },
          { name: 'Escalators:', value: building.hasEscalators ? '✅' : '❌' },
          { name: 'Vertical conveyor belts:', value: building.hasVerticalConveyorBelts ? '✅' : '❌' },
          { name: 'Standard:', value: building.hasStandard ? '✅' : '❌' },
          { name: 'Horizontal conveyor belts:', value: building.hasHorizontalConveyorBelts ? '✅' : '❌' },
        ]},
        { id: 13, component: BuildingMaintenance1Question, fields: [
          { name: 'Structure maintenance:', value: building.structureMaintenance ? `${building.structureMaintenance}/5` : 'No grade provided' },
          { name: 'Lining maintenance:', value: building.liningMaintenance ? `${building.liningMaintenance}/5` : 'No grade provided' },
          { name: 'Water pipe maintenance:', value: building.waterPipeMaintenance ? `${building.waterPipeMaintenance}/5` : 'No grade provided' },
          { name: 'Sewage maintenance:', value: building.sewageMaintenance ? `${building.sewageMaintenance}/5` : 'No grade provided' },
        ] },
        { id: 14, component: BuildingMaintenance2Question, fields: [
          { name: 'Draining water from the roof maintenance:', value: building.drainingWaterFromTheRoofMaintenance ? `${building.drainingWaterFromTheRoofMaintenance}/5` : 'No grade provided' },
          { name: 'Heating/Cooling installations maintenance:', value: building.heatingAndCoolingInstallationsMaintenance ? `${building.heatingAndCoolingInstallationsMaintenance}/5` : 'No grade provided' },
          { name: 'Gas installations maintenance:', value: building.gasInstallationsMaintenance ? `${building.gasInstallationsMaintenance}/5` : 'No grade provided' },
          { name: 'High and low current electrical installations maintenance:', value: building.electricalInstallationsMaintenance ? `${building.electricalInstallationsMaintenance}/5` : 'No grade provided' },
        ] },
        { id: 15, component: BuildingMaintenance3Question, fields: [
          { name: 'Fire protection systems maintenance:', value: building.fireProtectionMaintenance ? `${building.fireProtectionMaintenance}/5` : 'No grade provided' },
          { name: 'Security systems maintenance:', value: building.securitySystemsMaintenance ? `${building.securitySystemsMaintenance}/5` : 'No grade provided' },
        ] },
        { id: 16, component: BuildingDeficienciesQuestion, fields: [
          ...(building.structureDeficiencyDetails ? [{ name: 'Structure deficiency details:', value: building.structureDeficiencyDetails }] : []) ,
          ...(building.liningDeficiencyDetails ? [{ name: 'Lining deficiency details:', value: building.liningDeficiencyDetails }] : []) ,
          ...(building.installationsDeficiencyDetails ? [{ name: 'Installations deficiency details:', value: building.installationsDeficiencyDetails }] : []) ,
          ...(building.structureDeficiencyDetails || building.liningDeficiencyDetails || building.installationsDeficiencyDetails ? [] : [{ name: 'No deficiencies were noted', value: ' ' }]),
          { name: 'Average age of residents:', value: building.averageAge},
          { name: 'Has special needs users:', value: building.hasSpecialNeedsUsers ? '✅' : '❌' }

        ] },
        // {id: 17, component: ImageUploadQuestion, fields: [
        //   {name: "Images:", 
        //   image: media?.map((media) => {
        //     return(
        //       <div>
        //         <img
        //           src={`https://nyhfwymlixgprmqazsvh.supabase.co/storage/v1/object/public/uploads/${address}/${media.name}`}
        //           alt="slika"
        //           width={100}
        //           height={100}
        //           style={{
        //             display: media.name
        //               .split(".")
        //               .pop()
        //               .toLowerCase()
        //               .match(/^(jpg|jpeg|png|webp|gif|bmp|avif|tiff|psd|eps|svg|ai|indd|xcf|raw|heic)$/)
        //               ? ""
        //               : "none",
        //           }}
        //         />
        //       </div>
        //     )
        //   })},
        //   {name: "PDF:", 
        //   image: media?.map((media) => {
        //     return (
        //       <div>
        //         {media.name.split(".").pop().toLowerCase() === "pdf" && (
        //           <>
        //             <a href={`https://nyhfwymlixgprmqazsvh.supabase.co/storage/v1/object/public/uploads/${address}/${media.name}`}>
        //               <img src="/pdf-icon.jpeg" alt="PDF icon" height={100} width={100} />
        //             </a>
        //           </>
        //         )}
        //       </div>
        //     );
        //   })}
        // ]}
        
      ]);
    }
  }, [building, address, getFile, media]);

  const clearError = () => {
    setError('');
  };

  const isBackDisabled = () => {
    // No problem for question 1
    if (selectedQuestion === 2 && (!building.address || !building.constructionYear)) return true;
    if (selectedQuestion === 3) {
        if (building.structureOfBuilding.includes("Basement") && (!building.floorsBelowGround || !building.floorsAboveGround )) return true;
        if (!building.structureOfBuilding.includes("Basement") && !building.floorsAboveGround) return true;
    }
    if (selectedQuestion === 4) {
        if (!validateDimensions()) return true;
    }
    // No problem for question 5
    if (selectedQuestion === 6) {
      if (!building.groundFloorLevel || 
        (building.structureOfBuilding.indexOf("Basement") !== -1 && !building.basementLevel) || 
        (building.structureOfBuilding.indexOf("Basement") !== -1 && !building.basementHeight) || 
        !building.groundFloorHeight || 
        !building.floorHeight || 
        (building.structureOfBuilding.indexOf("Attic") !== -1 && !building.atticHeight) || 
        !building.totalBuildingHeight) {
        return true;
      }
    }
    // No problem for question 7
    // No problem for question 8 
    // No problem for question 9
    // No problem for question 10
    // No problem for question 11
    // No problem for question 12
    // No problem for question 13
    // No problem for question 14
    // No problem for question 15
    if (selectedQuestion === 16) {
      if (!building.averageAge) return true;
    }
    return false;
  };

  const handleBack = () => {
    if (isBackDisabled()) {
      setError('Please fill out all required fields before going back.');
    } else {
      setError('');
      setSelectedQuestion(null);
    }
  };

  console.log(media)

  const handleBuildingUpdate = (field, value) => {
    const updatedBuilding = { ...building, [field]: value };
    saveBuildingData(updatedBuilding);
  };

  if (!building) return <p>Loading...</p>;

  return (
    <div className={styles.container}>
      {!selectedQuestion ? (
        <div>
          <div className={styles.questionsLayout}>
            <div>
              <h2>Current answers</h2>
            </div>
            {questions.map((q) => (
              <div key={q.id} className={styles.rectangle} onClick={() => setSelectedQuestion(q.id)}>
                <h3>Question {q.id}</h3>
                {q.fields.map((field, index) => (
                  <div key={index} className={styles.field}>
                    <span className={styles.fieldName}>{field.name}</span>
                    {field.image ? (
                      <div className={styles.fieldImageContainer}>
                        <img src={field.image} alt={field.name} className={styles.fieldImage} />
                      </div>
                    ) : (
                      <span className={`${styles.fieldValue} ${field.value === "MISSING" ? styles.missingValue : ""}`}>{field.value}</span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={saveBuildingData}>
              Save
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.form}>
          {React.createElement(questions.find((q) => q.id === selectedQuestion).component, {
            updateBuilding: handleBuildingUpdate,
            clearError,
            setValidationError: setError,
            passValidateDimensions: setValidateDimensions,
          })}
          {error && <div className={styles.errorMessage}>{error}</div>}
          <div className={styles.buttonContainer}>
            <button className={styles.button} onClick={handleBack}>
              Back
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBuildingDetails;
