import React, { useState, useEffect } from "react";
import { useFormData } from "../components/FormDataContext";
import LinearProgress from "@mui/material/LinearProgress";
import BuildingPurposeQuestion from "../questions/BuildingPurposeQuestion";
import CityAddresQuestion from "../questions/CityAddressQuestion";
import StructureOfBuildingQuestion from "../questions/StructureOfBuildingQuestion";
import BuildingBasisShapeQuestion from "../questions/BuildingBasisShapeQuestion";
import BuildingLocationQuestion from "../questions/BuildingLocationQuestion";
import HeightQuestion from "../questions/HeightQuestion";
import BuildingRoofDetailsQuestion from "../questions/BuildingRoofDetailsQuestion";
import ConstructiveSystemQuestion from "../questions/ConstructiveSystemQuestion";
import BuildingCoverQuestion from "../questions/BuildingCoverQuestion";
import BuildingIrregularitiesQuestion from "../questions/BuildingIrregularitiesQuestion";
import BuildingInterventionsQuestion from "../questions/BuildingInterventionsQuestion";
import BuildingCommunicationsQuestion from "../questions/BuildingCommunicationsQuestion";
import BuildingMaintenance1Question from "../questions/BuildingMaintenance1Question";
import BuildingMaintenance2Question from "../questions/BuildingMaintenance2Question";
import BuildingMaintenance3Question from "../questions/BuildingMaintenance3Question";
import BuildingDeficienciesQuestion from "../questions/BuildingDeficienciesQuestion";
import ImageUploadQuestion from "../questions/ImageUploadQuestion";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import "./css/MultiStepForm.css";

const MultiStepForm = () => {
  const { formData } = useFormData();
  const [step, setStep] = useState(0);
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [createdAt, setCreatedAt] = useState(null);

  useEffect(() => {
    const now = new Date().toISOString();
    setCreatedAt(now);
  }, []);

  const navigate = useNavigate();

  const clearError = () => {
    setError("");
  };

  const [pdfFile, setPdfFile] = useState(null);
  const [imageFiles, setImageFiles] = useState(null);

  const normalizeAddress = (text) => {
    return text.replace("Š", "S").replace("š", "s").replace("Č", "C").replace("č", "c").replace("Ć", "C").replace("ć", "c").replace("Ð", "D").replace("đ", "d").replace("Dž", "Dz").replace("dž", "dz").replace("Ž", "Z").replace("ž", "z")
  } 

  const handleSubmitFiles = async () => {
    const normalAddress = normalizeAddress(formData.address)
    if (Array.isArray(imageFiles)) {
      imageFiles.forEach((imageFile) => {
        supabase.storage.from(`uploads/${normalAddress}`).upload(imageFile.name, imageFile);
      });
    }

    if (pdfFile) {
      supabase.storage.from(`uploads/${normalAddress}`).upload(pdfFile.name, pdfFile);
    }
  };

  const steps = [<BuildingPurposeQuestion />, <CityAddresQuestion />, <StructureOfBuildingQuestion />, <BuildingBasisShapeQuestion setValidationError={setValidationError} />, <BuildingLocationQuestion />, <HeightQuestion />, <BuildingRoofDetailsQuestion />, <ConstructiveSystemQuestion />, <BuildingCoverQuestion />, <BuildingIrregularitiesQuestion />, <BuildingInterventionsQuestion />, <BuildingCommunicationsQuestion />, <BuildingMaintenance1Question />, <BuildingMaintenance2Question />, <BuildingMaintenance3Question />, <BuildingDeficienciesQuestion />, <ImageUploadQuestion imageFiles={imageFiles} setImageFiles={setImageFiles} pdfFile={pdfFile} setPdfFile={setPdfFile} />];

  const user = JSON.parse(localStorage.getItem("user"));
  const author = user.user.email;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      createdAt: createdAt,
      author,
      buildingPurpose: formData.buildingPurpose,
      address: formData.address,
      city: formData.city,
      constructionYear: formData.constructionYear,
      structureOfBuilding: formData.structureOfBuilding,
      floorsBelowGround: formData.floorsBelowGround,
      floorsAboveGround: formData.floorsAboveGround,
      basisShape: formData.basisShape,
      dimensions: formData.dimensions,
      buildingLocation: formData.buildingLocation,
      locationStringForm: formData.locationStringForm,
      groundFloorLevel: formData.groundFloorLevel,
      basementLevel: formData.basementLevel,
      basementHeight: formData.basementHeight,
      groundFloorHeight: formData.groundFloorHeight,
      floorHeight: formData.floorHeight,
      atticHeight: formData.atticHeight,
      totalBuildingHeight: formData.totalBuildingHeight,
      roofType: formData.roofType,
      roofMaterial: formData.roofMaterial,
      constructiveSystem: formData.constructiveSystem,
      cover: formData.cover,
      buildingShape: formData.buildingShape,
      structureIrregularities: formData.structureIrregularities,
      extensionDetails: formData.extensionDetails,
      upgradeDetails: formData.upgradeDetails,
      strengtheningDetails: formData.strengtheningDetails,
      weakeningDetails: formData.weakeningDetails,
      hasStairs: formData.hasStairs,
      hasElevators: formData.hasElevators,
      hasEscalators: formData.hasEscalators,
      hasVerticalConveyorBelts: formData.hasVerticalConveyorBelts,
      hasStandard: formData.hasStandard,
      hasHorizontalConveyorBelts: formData.hasHorizontalConveyorBelts,
      structureMaintenance: +formData.structureMaintenance,
      liningMaintenance: +formData.liningMaintenance,
      waterPipeMaintenance: +formData.waterPipeMaintenance,
      sewageMaintenance: +formData.sewageMaintenance,
      drainingWaterFromTheRoofMaintenance: +formData.drainingWaterFromTheRoofMaintenance,
      heatingAndCoolingInstallationsMaintenance: +formData.heatingAndCoolingInstallationsMaintenance,
      gasInstallationsMaintenance: +formData.gasInstallationsMaintenance,
      electricalInstallationsMaintenance: +formData.electricalInstallationsMaintenance,
      fireProtectionMaintenance: +formData.fireProtectionMaintenance,
      securitySystemsMaintenance: +formData.securitySystemsMaintenance,
      structureDeficiencyDetails: formData.structureDeficiencyDetails,
      liningDeficiencyDetails: formData.liningDeficiencyDetails,
      installationsDeficiencyDetails: formData.installationsDeficiencyDetails,
      hasSpecialNeedsUsers: formData.hasSpecialNeedsUsers,
      averageAge: formData.averageAge,
    };

    const { error } = await supabase.from("buildings").insert([data]);

    if (error) {
      console.log(error);
    } else {
      await handleSubmitFiles();
      navigate("/");
    }
  };

  const handleNext = () => {
    if (isNextDisabled()) {
      setError("Please correctly fill out the form before proceeding.");
    } else {
      setError("");
      if (step < steps.length - 1) {
        setStep(step + 1);
      }
    }
  };

  const handlePrevious = () => {
    setError("");
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const isNextDisabled = () => {
    if (step === steps.length - 1) {
      return true;
    }
    if (step === 0 && !formData.buildingPurpose) {
      return true;
    }
    if (step === 1 && (!formData.city || !formData.address || !formData.constructionYear)) {
      return true;
    }
    if (step === 2 && (!formData.structureOfBuilding || (formData.structureOfBuilding.indexOf("Basement") !== -1 && !formData.floorsBelowGround) || (!formData.structureOfBuilding.includes("Basement") && !formData.floorsAboveGround))) {
      return true;
    }
    if (step === 3 && validationError) {
      return true;
    }
    if (step === 4 && !formData.buildingLocation) {
      return true;
    }
    if (step === 5 && (!formData.groundFloorLevel || (formData.structureOfBuilding.indexOf("Basement") !== -1 && !formData.basementLevel) || (formData.structureOfBuilding.indexOf("Basement") !== -1 && !formData.basementHeight) || !formData.groundFloorHeight || !formData.floorHeight || (formData.structureOfBuilding.indexOf("Attic") !== -1 && !formData.atticHeight) || !formData.totalBuildingHeight)) {
      return true;
    }
    if (step === 6 && (!formData.roofType || !formData.roofMaterial)) {
      return true;
    }
    if (step === 7 && !formData.constructiveSystem) {
      return true;
    }
    if (step === 8 && !formData.cover) {
      return true;
    }
    // step 9 is skipped since there is no validation in that question
    if (step === 10) {
      if (formData.extensionChecked && !formData.extensionDetails) {
        return true;
      }
      if (formData.upgradeChecked && !formData.upgradeDetails) {
        return true;
      }
      if (formData.strengtheningChecked && !formData.strengtheningDetails) {
        return true;
      }
      if (formData.weakeningChecked && !formData.weakeningDetails) {
        return true;
      }
    }
    //step 11 is skipped since there is no validation in that question
    // if (step === 12 && (!formData.structureMaintenance || !formData.liningMaintenance || !formData.waterPipeMaintenance || !formData.sewageMaintenance || !formData.drainingWaterFromTheRoofMaintenance || !formData.gasInstallationsMaintenance)) {
    //   return true;
    // }
    // if (step === 13 && (!formData.electricalInstallationsMaintenance || !formData.fireProtectionMaintenance || !formData.securitySystemsMaintenance)) {
    //   return true;
    // }
    if (step === 15) {
      if (formData.structureDeficienciesChecked && !formData.structureDeficiencyDetails) {
        return true;
      }
      if (formData.liningDeficienciesChecked && !formData.liningDeficiencyDetails) {
        return true;
      }
      if (formData.installationsDeficienciesChecked && !formData.installationsDeficiencyDetails) {
        return true;
      }
      if (!formData.averageAge) {
        return true;
      }
    }
    //if any questions are added, continue filling
    return false;
  };

  return (
    <div className='form'>
      {step === 0 && <BuildingPurposeQuestion clearError={clearError}/>}
      {step === 1 && <CityAddresQuestion clearError={clearError} />}
      {step === 2 && <StructureOfBuildingQuestion clearError={clearError} />}
      {step === 3 && <BuildingBasisShapeQuestion clearError={clearError} setValidationError={setValidationError} />}
      {step === 4 && <BuildingLocationQuestion clearError={clearError} />}
      {step === 5 && <HeightQuestion clearError={clearError} />}
      {step === 6 && <BuildingRoofDetailsQuestion clearError={clearError} />}
      {step === 7 && <ConstructiveSystemQuestion clearError={clearError} />}
      {step === 8 && <BuildingCoverQuestion clearError={clearError} />}
      {step === 9 && <BuildingIrregularitiesQuestion clearError={clearError} />}
      {step === 10 && <BuildingInterventionsQuestion clearError={clearError} />}
      {step === 11 && <BuildingCommunicationsQuestion clearError={clearError} />}
      {step === 12 && <BuildingMaintenance1Question clearError={clearError} />}
      {step === 13 && <BuildingMaintenance2Question clearError={clearError} />}
      {step === 14 && <BuildingMaintenance3Question clearError={clearError} />}
      {step === 15 && <BuildingDeficienciesQuestion clearError={clearError} />}
      {step === 16 && <ImageUploadQuestion clearError={clearError} imageFiles={imageFiles} setImageFiles={setImageFiles} pdfFile={pdfFile} setPdfFile={setPdfFile} />}

      <LinearProgress
        variant="determinate"
        value={((step + 1) / steps.length) * 100}
        sx={{
          backgroundColor: "#e0e0e0",
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#182771",
          },
          height: 4,
        }}
      />
      {error && <div className="error-message">{error}</div>}
      <div className="button-container">
        {step !== 0 && (
          <button className="prev-button" onClick={handlePrevious}>
            &lt;&lt; Previous
          </button>
        )}

        {step === 0 && (
          <button className="prev-button hidden-prev-button">
            &lt;&lt; Previous
          </button>
        )}

        {step !== 16 && (
          <button className="next-button" onClick={handleNext}>
            Next question
          </button>
        )}

        {step === 16 && (
          <button className="next-button" onClick={handleSubmit}>
            Submit form
          </button>
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;
