import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import styles from "./styles/Navbar.module.css";


const Navbar = ({ setUser }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const isDatabasePage = location.pathname === "/database";
  const mobileNavRef = useRef(null);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileNavRef.current && !mobileNavRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('scroll', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <nav className={isDatabasePage ? styles.navbarDatabase : styles.navbarDefault} ref={mobileNavRef}>
      <div className={styles.burgerMenu} onClick={toggleMenu}>
        <div className={styles.burgerBar}></div>
        <div className={styles.burgerBar}></div>
        <div className={styles.burgerBar}></div>
      </div>
      <h1>Build Collector</h1>
      <div className={`${styles.link} ${menuOpen ? `${styles.mobileNav} ${styles.open}` : styles.mobileNav}`}>
        <Link
          to="/"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#182771",
          }}
          onClick={() => setMenuOpen(false)}
        >
          Home
        </Link>
        <Link
          to="/database"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#182771",
          }}
          onClick={() => setMenuOpen(false)}
        >
          Database
        </Link>
        <Link
          to="/create"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#182771",
          }}
          onClick={() => setMenuOpen(false)}
        >
          +New model
        </Link>
        <Link
          to="/about-us"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#182771",
          }}
          onClick={() => setMenuOpen(false)}
        >
          About us
        </Link>
        <Link
          to="/login"
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: "18px",
          }}
          onClick={() => {
            setUser(null);
            localStorage.removeItem("user");
            localStorage.removeItem("sb-nyhfwymlixgprmqazsvh-auth-token");
            setMenuOpen(false);
          }}
        >
          Log out
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;