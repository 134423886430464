import React, {useState} from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingLocationQuestion.module.css'

const BuildingLocationQuestion = ({clearError}) => {
  const { formData, updateFormData } = useFormData();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;

  const buildingLocation = building?.buildingLocation || formData?.buildingLocation;
  const updateFunction = updateBuilding || updateFormData;


  const handleChange = (e) => {
    updateFunction('buildingLocation', e.target.value);
    clearError();
  };

  const getLocation = async () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const newLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        setLocation(newLocation);
        updateFormData('locationStringForm', `${newLocation.latitude} - ${newLocation.longitude}`);
        setLoading(false);
      },
      (error) => {
        console.error("Error retrieving location:", error);
        setLoading(false);
      }
    );
  };

  const locationStringForm = location ? `${location.latitude} - ${location.longitude}` : formData.locations;

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 5</h4>
      <h2 className={styles.title}>Building location</h2>
      <div className={styles.options} >
        {[
          { value: 'Free standing', label: 'Free standing' },
          { value: 'Next to the buiding on one side', label: 'Next to the buiding on one side' },
          { value: 'Next to the building from both sides', label: 'Next to the building from both sides' },
        ].map((option) => (
          <label key={option.value} className={styles.label}>
            <input
              type="radio"
              name="buildingLocation"
              value={option.value}
              checked={buildingLocation === option.value}
              onChange={handleChange}
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
      <div className={styles.separator}></div>
      <div className={styles.locationSection}>
        <h2 className={styles.gpsLabel}>GPS</h2>
        {loading ? (
          <p>Loading location...</p>
        ) : (
          <>
            <p>{locationStringForm}</p>
          </>
        )}
        <button className={styles.gpsButton} onClick={getLocation}>Add location</button>
      </div>
    </div>
  );
};

export default BuildingLocationQuestion;
