
import "./components/css/AboutUs.css";

const AboutUs = () => {
  return (
    <>
      <div className="about-section">
        <h1>Collect. Analyze. Improve.</h1>
        <p>Manage your building data visually. Create, analyze, update, and delete. All in one place.</p>
      </div>
      <div>
        {/* <h2 style={{ textAlign: "center", color: "#182771" }}>Our Team</h2> */}
        <div class="column">
          <div class="card">
            <div class="container">
              <h2>Milan Trivunić</h2>
              <p class="title">Author</p>
              <p>trule@uns.ac.rs</p>
              {/* <p><button class="button">Contact</button></p> */}
            </div>
          </div>

          <div class="card">
            <div class="container">
              <h2>Željko Jakšić</h2>
              <p class="title">Author</p>
              <p>alt96@uns.ac.rs</p>
              {/* <p><button class="button">Contact</button></p> */}
            </div>
          </div>

          <div class="card">
            <div class="container">
              <h2>Dušanka Plazina-Pevač</h2>
              <p class="title">Author</p>
              <p>dusanka.plazina@iee.rs</p>
              {/* <p><button class="button">Contact</button></p> */}
            </div>
          </div>
        </div>
         

        <div class="column">
          <div class="card">
            <div class="container">
              <h2>Vladimir Mučenski</h2>
              <p class="title">Author</p>
              <p>mucenskiv@uns.ac.rs</p>
              {/* <p><button class="button">Contact</button></p> */}
            </div>
          </div>
          
          <div class="card">
            <div class="container">
              <h2>Igor Peško</h2>
              <p class="title">Author</p>
              <p>igorbp@uns.ac.rs</p>
              {/* <p><button class="button">Contact</button></p> */}
            </div>
          </div>
        </div>

        <div class="column">
          <div class="card">
            <div class="container">
              <h2>Aleksa Vučković</h2>
              <p class="title">Creator</p>
              <p>aleksa.vuckovic02@gmail.com</p>
              {/* <p><button class="button">Contact</button></p> */}
            </div>
          </div>

          <div class="card">
            <div class="container">
              <h2>Dimitrije Pevač</h2>
              <p class="title">Creator</p>
              <p>dimitrije.pevac@gmail.com</p>
              {/* <p><button class="button">Contact</button></p> */}
            </div>
        </div>

        </div>
      </div>
    </>
  );
};

export default AboutUs;
