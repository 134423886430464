import React from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/HeightQuestion.module.css'

const HeightQuestion = ({clearError}) => {
    const { formData, updateFormData } = useFormData();

    const editContextData = useEditContext();
    const building = editContextData?.building;
    const updateBuilding = editContextData?.updateBuilding;
  
    const structureOfBuilding = building?.structureOfBuilding || formData?.structureOfBuilding;
    const basementLevel = building?.basementLevel || formData?.basementLevel;
    const basementHeight = building?.basementHeight || formData?.basementHeight;
    const groundFloorLevel = building?.groundFloorLevel || formData?.groundFloorLevel;
    const groundFloorHeight = building?.groundFloorHeight || formData?.groundFloorHeight;
    const floorHeight = building?.floorHeight || formData?.floorHeight;
    const atticHeight = building?.atticHeight || formData?.atticHeight;
    const totalBuildingHeight = building?.totalBuildingHeight || formData?.totalBuildingHeight;
    const updateFunction = updateBuilding || updateFormData;

    const handleInputChange = (field, value) => {
        const parsedValue = parseFloat(value.replace(/[^0-9/.]/g, ''));
            if (!isNaN(parsedValue)) {
                updateFunction(field, parsedValue);
                clearError();
            } 
            else {
               updateFunction(field, ""); 
            }
    };

    return (
        <div className={styles.container}>
            <h4 className={styles.questionNumber}>Question 6</h4>
            <h2 className={styles.title}>Building height details</h2>
            <div className={styles.inputGridContainer}>
                <div className={styles.inputGrid}>
                    {structureOfBuilding && structureOfBuilding.indexOf('Basement') !== -1 && (
                        <>
                            <div className={styles.gridItem}>
                                <label className={styles.label}>Basement level:</label>
                                <input
                                    type="number"
                                    inputMode="number"
                                    // pattern="[0-9\.]+"
                                    value={basementLevel}
                                    onChange={(e) => handleInputChange('basementLevel', e.target.value)}
                                    className={styles.input}
                                />
                            </div>
                            <div className={styles.gridItem}>
                                <label className={styles.label}>Basement height:</label>
                                <input
                                    type="number"
                                    inputMode="number"
                                    // pattern="[0-9\.]+"
                                    value={basementHeight}
                                    onChange={(e) => handleInputChange('basementHeight', e.target.value)}
                                    className={styles.input}
                                />
                            </div>
                        </>
                    )}

                            <div className={styles.gridItem}>
                                <label className={styles.label}>Ground floor level:</label>
                                <input
                                    type="number"
                                    inputMode="number"
                                    // pattern="[0-9\.]+"
                                    value={groundFloorLevel}
                                    onChange={(e) => handleInputChange('groundFloorLevel', e.target.value)}
                                    className={styles.input}
                                />
                            </div>

                            <div className={styles.gridItem}>
                                <label className={styles.label}>Ground floor height:</label>
                                <input
                                    type="number"
                                    inputMode="number"
                                    // pattern="[0-9\.]+"
                                    value={groundFloorHeight}
                                    onChange={(e) => handleInputChange('groundFloorHeight', e.target.value)}
                                    className={styles.input}
                                />
                            </div>

                            <div className={styles.gridItem}>
                                <label className={styles.label}>Floor height:</label>
                                <input
                                    type="number"
                                    inputMode="number"
                                    // pattern="[0-9\.]+"
                                    value={floorHeight}
                                    onChange={(e) => handleInputChange('floorHeight', e.target.value)}
                                    className={styles.input}
                                />
                            </div>

                    {structureOfBuilding && structureOfBuilding.indexOf('Attic') !== -1 && (
                        <div className={styles.gridItem}>
                            <label className={styles.label}>Attic height:</label>
                            <input
                                type="number"
                                inputMode="number"
                                // pattern="[0-9\.]+"
                                value={atticHeight}
                                onChange={(e) => handleInputChange('atticHeight', e.target.value)}
                                className={styles.input}
                            />
                        </div>
                    )}

                        <div className={styles.gridItem}>
                            <label className={styles.label}>Total building height:</label>
                            <input
                                type="number"
                                inputMode="number"
                                // pattern="[0-9\.]+"
                                value={totalBuildingHeight}
                                onChange={(e) => handleInputChange('totalBuildingHeight', e.target.value)}
                                className={styles.input}
                            />
                        </div>
                </div>
            </div>
        </div>
    );
};

export default HeightQuestion;
