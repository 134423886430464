import * as XLSX from 'xlsx'
import { saveAs } from "file-saver";
import styles from './styles/Database.module.css'

const ExcelExportComponent  = ({buildings}) => {

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(buildings);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    saveAs(blob, "exportedData.xlsx");

    };
        return (
        <button onClick={exportToExcel} className={styles.excelButton}>Export to excel</button>
    );
}

export default ExcelExportComponent;




