import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../config/supabaseClient';

export const EditContext = createContext();

export const useEditContext = () => useContext(EditContext);

export const EditProvider = ({ children, buildingId }) => {
  const [building, setBuilding] = useState(null);

  useEffect(() => {
    const fetchBuilding = async () => {
      const { data, error } = await supabase
        .from('buildings')
        .select('*')
        .eq('id', buildingId)
        .single();

      if (error) {
        console.error(error);
      } else {
        setBuilding(data);
      }
    };

    fetchBuilding();
  }, [buildingId]);

  const updateBuilding = (field, value) => {
    setBuilding((prevData) => ({ ...prevData, [field]: value }));
  };

  const saveBuildingData = async () => {
    if ([
      'basementLevel',
      'basementHeight',
      'atticHeight'
    ].some(field => building[field] === 'MISSING')) {
      alert('Please fill out all required fields before saving.');
      return;
    }

    try {
      const { error } = await supabase
        .from('buildings')
        .update(building)
        .eq('id', building.id);

      if (error) {
        console.error('Error updating building:', error);
      } else {
        alert('Building updated successfully');
        window.location.href = '/database';
      }
    } catch (err) {
      console.error('Error saving building data:', err);
    }
  };

  return (
    <EditContext.Provider value={{ building, updateBuilding, saveBuildingData }}>
      {children}
    </EditContext.Provider>
  );
};
