import React from "react";
import "../styles/filterList.css"
import "../index.css"
import CustomDropdown from "./CustomDropdown";
import CustomInput from "./CustomInput";

const FilterList = ({ 
    filterListOpen, 
    filters, 
    handleFilterChange,
    handleResetFilters
}) => {

    const handleClick = (event) => {
        event.stopPropagation();
    };

    const resetFilters = () => {
    handleResetFilters(); 
    };

    const buildingPurposeOptions = [
        "Any",
        "Residental",
        "Business- residential",
        "Business",
        "Hotels",
        "Shopping malls",
        "Garages",
        "Theatres, movie theatres",
        "Kindergardens, schools and faculties",
        "Hospitals",
        "Bus stations, train stations, airports"
    ];

    const cityOptions = [
        "Any",
        "Belgrade",
        "Novi Sad",
        "Kragujevac"
    ]

    const floorOptions = [
        "Any",
        "0-5",
        "6-10",
        "11-15",
        "16-20"
    ]

    const constructiveSystemOptions = [
        "Any",
        "Massive masonry",
        "Massive masonry reinforced with RC elements",
        "Reinforced walls",
        "Skeletal RC",
        "Prefabricated panel",
        "Prefabricated skeletal",
        "Prefabricated skeletal prestressed IMS",
        "Prefabricated skeletal prestressed IMS with stiffening walls"
    ]

    return (
    <nav className={`filters ${filterListOpen ? "open" : ""}`} onClick={handleClick}>
        <h2 className="filter-list-h1"> FILTER THE BUILDINGS </h2>

        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}> 
            <h3 className="filter-list-h3">By purpose</h3>
            <CustomDropdown
                options={buildingPurposeOptions}
                selectedOption={filters.buildingPurpose}
                onOptionSelect={(value) => handleFilterChange("buildingPurpose", value)}
            />
        </div>



        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}> 
            <h3 className="filter-list-h3">By city</h3>
            <CustomDropdown
                options={cityOptions}
                selectedOption={filters.city}
                onOptionSelect={(value) => handleFilterChange("city", value)}
            /> 
        </div>

        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}> 
            <h3 className="filter-list-h3">By number of floors</h3>
            <CustomDropdown
                options={floorOptions}
                selectedOption={filters.floorsAboveGround}
                onOptionSelect={(value) => handleFilterChange("floorsAboveGround", value)}
            />
        </div>
        

        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}> 
            <h3 className="filter-list-h3">By constructive system</h3>
            <CustomDropdown
                options={constructiveSystemOptions}
                selectedOption={filters.constructiveSystem}
                onOptionSelect={(value) => handleFilterChange("constructiveSystem", value)}
            />
        </div>
        
        <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}> 
            <h3 className="filter-list-h3">By address</h3>
            <CustomInput
                    placeholder="Enter address"
                    value={filters.address}
                    onChange={(value) => handleFilterChange("address", value)}
            />
        </div>

        <div className="button-container2">
            <button onClick={resetFilters} className="button">Reset Filters</button>
        </div>
    </nav>
    );
};

export default FilterList;
