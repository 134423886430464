import React from 'react';
import { useFormData } from '../components/FormDataContext';
import { useEditContext } from '../components/EditContext';
import styles from './css/BuildingMaintenanceQuestion.module.css'

const BuildingMaintenance2Question = ({clearError}) => {
  const { formData, updateFormData } = useFormData();
  const editContextData = useEditContext();
  const building = editContextData?.building;
  const updateBuilding = editContextData?.updateBuilding;
  const updateFunction = updateBuilding || updateFormData;

  const drainingWaterFromTheRoofMaintenance = building?.drainingWaterFromTheRoofMaintenance || formData?.drainingWaterFromTheRoofMaintenance;
  const heatingAndCoolingInstallationsMaintenance = building?.heatingAndCoolingInstallationsMaintenance || formData?.heatingAndCoolingInstallationsMaintenance;
  const gasInstallationsMaintenance = building?.gasInstallationsMaintenance || formData?.gasInstallationsMaintenance;
  const electricalInstallationsMaintenance = building?.electricalInstallationsMaintenance || formData?.electricalInstallationsMaintenance;

  const handleLabelClick = (field,value) => {
    const currentValue = field === "drainingWaterFromTheRoofMaintenance" ? drainingWaterFromTheRoofMaintenance :
    field === "heatingAndCoolingInstallationsMaintenance" ? heatingAndCoolingInstallationsMaintenance :
    field === "gasInstallationsMaintenance" ? gasInstallationsMaintenance :
    field === "electricalInstallationsMaintenance" ? electricalInstallationsMaintenance : '';

    const newValue = currentValue === value ? '' : value;
    updateFunction(field, newValue);
    clearError();
  };

  return (
    <div className={styles.container}>
      <h4 className={styles.questionNumber}>Question 14</h4>
      <h2 className={styles.title}>Maintenance 2/3 (optional)</h2>

      <label className={styles.label}>Draining water from the roof</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${drainingWaterFromTheRoofMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("drainingWaterFromTheRoofMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <label className={styles.label}>Heating/Cooling installations</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${heatingAndCoolingInstallationsMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("heatingAndCoolingInstallationsMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <label className={styles.label}>Gas installations</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${gasInstallationsMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("gasInstallationsMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <label className={styles.label}>High and low current electrical installations</label>
      <div className={styles.radioGroup}>
        <div className={styles.radioButtons}>
          {[1, 2, 3, 4, 5].map((value) => (
            <span key={value} className={`${styles.radioButtonLabel} ${electricalInstallationsMaintenance === value ? styles.selected : ""}`} onClick={() => handleLabelClick("electricalInstallationsMaintenance", value)}>
              {value}
            </span>
          ))}
        </div>
      </div>

      <div className={styles.separator} />
    </div>
  );
};

export default BuildingMaintenance2Question;
