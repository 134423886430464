import React from 'react';
import { EditProvider } from './components/EditContext';
import { FormDataProvider } from './components/FormDataContext';
import EditBuildingDetails from './EditBuildingDetails';
import { useParams } from 'react-router-dom';


const Update = () => {
  const { id } = useParams();

  return (
    <FormDataProvider>
      <EditProvider buildingId={id}>
        <EditBuildingDetails />
      </EditProvider>
    </FormDataProvider>
  );
};

export default Update;
